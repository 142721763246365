import React, { useEffect, useState } from "react";
import {Button, Card, Table, Tooltip, Modal, notification, Pagination} from 'antd';
import { instanceApi } from "../../../../services/Base/base.instance.services";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import { PolicyDraver } from "../../Draver";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PrivacyPolicy = () => {

    const [ data, setData ]                             = useState();
    const [ id, setId ]                                 = useState();
    const [ type, setType ]                             = useState();
    const [ title, setTitle ]                           = useState();
    const [ content, setContent ]                       = useState();
    const [ language, setLanguage ]                     = useState();
    const [ date, setDate ]                             = useState();
    const [ draverVisible, setDraverVisible ]           = useState(false);
    const [ total, setTotal ]                           = useState();

    const { confirm } = Modal;

    const deletePrivacyPolicy = (privacyPolicyId) => {
        confirm({
            title: `Bu gizlilik sözlemesini silmek istediğinize emin misiniz?`,
            okText: `Evet`,
            okType: 'danger',
            cancelText: `Hayır`,
            onOk() {
                instanceApi()
                    .delete('/policies/' + privacyPolicyId)
                    .then(res => {
                        window.location = '/policies';
                    })
                    .catch(err => {
                        console.log("err: ", err);
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const editPrivacyPolicy = (privacyPolicyId) => {
        window.location = '/policy/edit/' + privacyPolicyId;
    }

    const showPrivacyPolicyDetail = (privacyPolicy) => {
        setDraverVisible(true);
        setId(privacyPolicy.id);
        setType(privacyPolicy.type);
        setTitle(privacyPolicy.title);
        setContent(privacyPolicy.content);
        setLanguage(privacyPolicy.language);
        setDate(privacyPolicy.createdAt);
    }

    const columns = [
        {
            title: `Id`,
            dataIndex: 'id',
        },
        {
            title: "Tip",
            dataIndex: 'type'
        },
        {
            title: "Başlık",
            dataIndex: 'title'
        },
        {
            title: "Dil",
            dataIndex: 'language'
        },
        {
            title: "Tarih",
            dataIndex: 'createdAt'
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (_, elm) => (
                <div className="text-right d-flex justify-content-end">
                    <Tooltip title={'Düzenle'}>
                        <Button
                            style={{ marginRight: 10}}
                            className="mr-5"
                            icon={<EditOutlined />}
                            onClick={() => {
                                editPrivacyPolicy(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title='Göster'>
                        <Button
                            type="primary"
                            className="mr-2"
                            icon={<EyeOutlined />}
                            onClick={() => {
                                showPrivacyPolicyDetail(elm);
                            }}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title={'Sil'}>
                        <Button
                            style={{ marginLeft: '12px' }}
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                deletePrivacyPolicy(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const fetchPrivacyPolicies = () => {
        instanceApi()
            .get('/policies')
            .then(res => {
                setData(res.data.results);
                setTotal(res.data.totalResults);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        fetchPrivacyPolicies();
    },[]);

    const closeVisible = () => {
        setDraverVisible(false);
    }

    const paginationOnChange = (event) => {
        instanceApi()
            .get('/policies?page=' + event)
            .then(res => {
                setData(res.data.results);
            })
            .catch(err => console.log(err));
    }

    return(
        <>
            <Card bodyStyle={{ padding: '0px'}}>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={data} pagination={false} />
                    <Pagination
                        style={{ padding: "15px"}}
                        defaultCurrent={1}
                        defaultPageSize={10}
                        total={total}
                        onChange={paginationOnChange}
                    />
                </div>
                <PolicyDraver
                    visible={draverVisible}
                    close={closeVisible}
                    id={id}
                    type={type}
                    title={title}
                    content={content}
                    language={language}
                    date={date}
                />
            </Card>
        </>
    )
}
export default PrivacyPolicy