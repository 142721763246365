import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	//render routtes
	const renderRoutes = props => {
		if (sessionStorage.getItem('token')) {
			return <Component {...props} />;
		}

		return (
			<Redirect
				to={{
					pathname: '/',
					state: {
						from: props.location,
					},
				}}
			/>
		);
	};
	return <Route {...rest} render={renderRoutes} />;
};
