import React from 'react';
import { Divider, Drawer } from 'antd';

/**
 *
 * @param visible
 * @param close
 * @param userRole
 * @param followersCount
 * @param userFullName
 * @param userName
 * @param email
 * @param message
 * @param type
 * @param createdAt
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const ReportDraver = ({
	visible,
	close,
	userRole,
	followersCount,
	userFullName,
	userName,
	email,
	message,
	type,
	createdAt,
	id,
	sub,
}) => {
	return (
		<>
			<Drawer
				width={400}
				placement="right"
				onClose={close}
				closable={false}
				visible={visible}>
				<div className="">
					<h6 className="text-muted text-uppercase mb-3">
						Kullanıcı Detay
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Rol:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{userRole}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Takipçi Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{followersCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kullanıcı Adı Soyadı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{userFullName}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kullanıcı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{userName}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Email:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{email}
						</span>
					</p>
					<Divider />
					<h6 className="text-muted text-uppercase mb-3">
						Mesaj Detay
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Id:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{id}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Rapora Konu Olan Özne ID:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{sub}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Mesaj:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{message}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Tip:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{type}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Tarih:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{createdAt}
						</span>
					</p>
				</div>
			</Drawer>
		</>
	);
};
export default ReportDraver;
