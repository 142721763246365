import React from "react";
import {Divider, Drawer} from "antd";

/**
 *
 * @param visible
 * @param close
 * @param title
 * @param introduction
 * @param slug
 * @param publicationType
 * @param isActive
 * @param likesCount
 * @param totalRating
 * @param totalRatingCount
 * @param categoryTitle
 * @param publishDate
 * @param workType
 * @param creatorFullName
 * @param creatorUserName
 * @param id
 * @param createdAt
 * @returns {JSX.Element}
 * @constructor
 */
const WorkDraver = ({
                        visible,
                        close,
                        title,
                        introduction,
                        slug,
                        publicationType,
                        isActive,
                        likesCount,
                        totalRating,
                        totalRatingCount,
                        categoryTitle,
                        publishDate,
                        workType,
                        creatorFullName,
                        creatorUserName,
                        id,
                        createdAt
                    }) => {

    return(
        <>
            <Drawer
                width={400}
                placement="right"
                onClose={close}
                closable={false}
                visible={visible}>
                <div>
                    <h6 className="text-muted text-uppercase mb-3">
                        Genel Bilgiler
                    </h6>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Id: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{id}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Başlık: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{title}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Giriş: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{introduction}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Açıklama: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{slug}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Yayın Tipi: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{publicationType}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Durumu: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{isActive === true ? 'Aktif' : 'Pasif'}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Beğeni Sayısı: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{likesCount}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Toplam Değerlendirme: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{totalRating}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Toplam Değerlendirme Sayısı: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{totalRatingCount}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Kategori Başlık: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{categoryTitle}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Yayınlanma Tarihi: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{publishDate}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Kitap Tipi: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{workType}</span>
                    </p>
                    <Divider/>
                    <h6 className="text-muted text-uppercase mb-3">
                        Oluşturan Kişi Bilgileri
                    </h6>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>İsim Soyisim: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{creatorFullName}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Kullanıcı Adı: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{creatorUserName}</span>
                    </p>
                    <Divider/>
                    <h6 className="text-muted text-uppercase mb-3">
                        Tarih Bilgisi
                    </h6>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Tarih: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{createdAt}</span>
                    </p>
                </div>
            </Drawer>
        </>
    )
}
export default WorkDraver