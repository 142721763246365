import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Pagination,
} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CategoryTable = () => {
	const [data, setData] = useState();
	const [total, setTotal] = useState();

	const { confirm } = Modal;

	const deleteCategory = categoryId => {
		confirm({
			title: `Bu kategoriyi silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/categories/' + categoryId)
					.then(res => {
						window.location = '/categories';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const editCategory = categoryId => {
		window.location = '/category/edit/' + categoryId;
	};

	const columns = [
		{
			title: `Id`,
			dataIndex: 'id',
		},
		{
			title: 'Başlık',
			dataIndex: ['title', 'tr'],
		},
		{
			title: `İkon`,
			dataIndex: 'icon',
			render: icon => <img src={icon} style={{ width: '70px' }} />,
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Düzenle'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5"
							icon={<EditOutlined />}
							onClick={() => {
								editCategory(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteCategory(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const fetchCategories = () => {
		instanceApi()
			.get('/categories')
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const paginationOnChange = event => {
		instanceApi()
			.get('/categories?page=' + event)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log(err));
	};

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
			</Card>
		</>
	);
};
export default CategoryTable;
