import React from 'react';
import {
	SIDE_NAV_WIDTH,
	SIDE_NAV_COLLAPSED_WIDTH,
	NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { Grid } from 'antd';
const { useBreakpoint } = Grid;

const getLogoWidthGutter = (status) => {
	if(status === true) {
		return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
	} else {
		return `${SIDE_NAV_WIDTH}px`;
	}
};

const getLogo = (status) => {
	if(status === true) {
		return '/img/logo.png';
	} else {
		return '/img/logo.png';
	}
};

const getLogoDisplay = (isMobile, mobileLogo) => {
	if (isMobile && !mobileLogo) {
		return 'd-none';
	} else {
		return 'logo';
	}
};

const Logo = ({ status }) => {
	return (
		<div
			className="logo"
			style={{ width: `${getLogoWidthGutter(status)}`}}>
			<img style={{ width: "31%"}} src={getLogo(status)} alt={`${APP_NAME} logo`} />
		</div>
	);
};

export default Logo;
