import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Tooltip, Modal, notification } from 'antd';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { Pagination } from 'antd';
import styled from 'styled-components';

// Define an object that maps status values to styles
const statusStyles = {
	waiting: {
		backgroundColor: '#cfcf47',
		color: 'black',
	},
	approved: {
		backgroundColor: '#21a621',
		color: 'white',
	},
	rejected: {
		backgroundColor: '#ca4141',
		color: 'white',
	},
};

const StatusWrapper = styled.div`
	background-color: ${props =>
		statusStyles[props.status]?.backgroundColor || 'blue'};
	border-radius: 50px;
	text-align: center;
	border: 1px solid #999;
	color: ${props => statusStyles[props.status]?.color || 'black'};
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

const ShowcaseRequestTable = () => {
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const [role, setRole] = useState();
	const [fullName, setFullName] = useState();
	const [username, setUsername] = useState();
	const [email, setEmail] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [gender, setGender] = useState();
	const [id, setId] = useState();
	const [total, setTotal] = useState();
	const [filterLoading, setFilterLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	const { confirm } = Modal;
	const statusTypesLookUp = {
		waiting: 'Beklemede',
		approved: 'Onaylandı',
		rejected: 'Reddedildi',
	};
	const showUserDetail = user => {
		setVisible(true);
		setRole(user.role);
		setFullName(user.fullname);
		setUsername(user.username);
		setEmail(user.email);
		setCreatedAt(user.createdAt);
		setGender(user.gender);
		setId(user.id);
	};

	const ItemStatus = ({ status }) => {
		return (
			<StatusWrapper status={status}>
				{statusTypesLookUp[status]}
			</StatusWrapper>
		);
	};

	const getAllShowcaseRequestFromServer = (page = 1) => {
		instanceApi()
			.get('/showcase-requests?limit=10&page=' + page)
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		//Gel all
		getAllShowcaseRequestFromServer();
	}, []);

	//Approve
	const handleOnPressApprovedButton = item => {
		confirm({
			title: `Onaylamak üzeresin, emin misin?`,
			okText: `Evet`,
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.post('/showcase-requests/' + item.id + '/approve')
					.then(res => {
						getAllShowcaseRequestFromServer();
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	//Reject
	const handleOnPressRejectedButton = item => {
		confirm({
			title: `Reddetmek üzeresin, emin misin?`,
			okText: `Evet`,
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.post('/showcase-requests/' + item.id + '/reject')
					.then(res => {
						getAllShowcaseRequestFromServer();
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const paginationOnChange = event => {
		getAllShowcaseRequestFromServer(event);
	};

	const filterFormOnFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const columns = [
		{
			title: `İsim`,
			dataIndex: ['user', 'fullname'],
		},
		{
			title: `Kullanıcı Adı`,
			dataIndex: 'user',
			render: user => {
				return (
					<a href={`user/edit/${user?.id}`}>{`@${user?.username}`}</a>
				);
			},
		},
		{
			title: 'E-mail',
			dataIndex: 'user',
			render: user => {
				return <a href={`mailto:${user?.email}`}>{`${user?.email}`}</a>;
			},
		},

		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},

		{
			title: 'Durum',
			dataIndex: 'status',
			render: status => {
				return <ItemStatus status={status} />;
			},
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					{['waiting', 'rejected'].includes(elm.status) && (
						<Tooltip title={'Onayla'}>
							<Button
								style={{
									marginRight: 10,
									backgroundColor: 'green',
									color: 'white',
								}}
								className="mr-5"
								icon={<CheckOutlined />}
								onClick={() => {
									handleOnPressApprovedButton(elm);
								}}
								size="small">
								Onayla
							</Button>
						</Tooltip>
					)}
					{['waiting', 'approved'].includes(elm.status) && (
						<Tooltip title={'Red'}>
							<Button
								type="danger"
								className="mr-2"
								icon={
									<CloseCircleOutlined
										style={{ fontSize: 18 }}
									/>
								}
								onClick={() => {
									handleOnPressRejectedButton(elm);
								}}
								size="small"
							/>
						</Tooltip>
					)}
				</div>
			),
		},
	];

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
			</Card>
		</>
	);
};
export default ShowcaseRequestTable;
