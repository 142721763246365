import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
	Select,
	InputNumber,
	Pagination,
	Checkbox,
} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { WorkDraver } from '../../../molecules/Draver';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { device } from '../../../../utils/device';
import { createHomeList } from '../../../../services/HomeList';
import { useHistory } from 'react-router-dom';

const ButtonEpisode = styled.div`
	width: 30%;
	padding: 15px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

const FilterEpisode = styled.div`
	width: 100%;
	padding: 15px;
`;

const FilterFormLine = styled.div`
	width: 100%;
	display: flex;
`;

const FilterSearchButtonEpisode = styled.div`
	margin-left: auto;
	margin-top: 30px;
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const WorkTable = () => {
	const [data, setData] = useState([]);
	const [visible, setVisible] = useState(false);
	const [title, setTitle] = useState();
	const [introduction, setIntroduction] = useState();
	const [slug, setSlug] = useState();
	const [publicationType, setPublicationType] = useState();
	const [isActive, setIsActive] = useState();
	const [likesCount, setLikesCount] = useState();
	const [totalRating, setTotalRating] = useState();
	const [totalRatingCount, setTotalRatingCount] = useState();
	const [workType, setWorkType] = useState();
	const [categoryTitle, setCategoryTitle] = useState();
	const [publishDate, setPublishDate] = useState();
	const [creatorFullName, setCreatorFullName] = useState();
	const [creatorUserName, setCreatorUserName] = useState();
	const [id, setId] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [works, setWorks] = useState([]);
	const [tempWorks, setTempWorks] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [buttonVisible, setButtonVisible] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filterLoading, setFilterLoading] = useState(false);
	const [total, setTotal] = useState();
	const [deleteReason, setDeleteReason] = useState('');
	const history = useHistory();

	const { confirm } = Modal;

	const showWorkDetail = work => {
		setVisible(true);
		setTitle(work.title.tr);
		setIntroduction(work.introduction.tr);
		setSlug(work.slug.tr);
		setPublicationType(work.publicationType);
		setIsActive(work.isActive);
		setLikesCount(work.likesCount);
		setTotalRating(work.totalRating);
		setTotalRatingCount(work.totalRatingCount);
		setCategoryTitle(work.category.title.tr);
		setPublishDate(work.publishDate);
		setWorkType(work.workType);
		setCreatorFullName(work.creator.fullname);
		setCreatorUserName(work.creator.username);
		setCreatedAt(work.createdAt);
		setId(work.id);
	};

	const handleDelete = (workId, reason) => {
		if (!reason.trim()) {
			return notification.error({
				message: 'Silme nedeni giriniz.',
			});
		}
		instanceApi()
			.delete(`/works/${workId}?reason=${reason.trim()}`)
			.then(res => {
				window.location = '/works';
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const deleteWork = workId => {
		const dialog = confirm({
			title: `Bu kitabı silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				handleDelete(workId, deleteReason);
			},
			onCancel() {
				console.log('Cancel');
			},
			content: (
				<Input
					type="text"
					onChange={e => {
						setDeleteReason(e.target.value);
						dialog.update({
							onOk() {
								handleDelete(workId, e.target.value);
							},
						});
					}}
					placeholder="Silme nedeninizi giriniz (kullanıcıya bildirilecektir)."
				/>
			),
		});
	};

	const columns = [
		{
			title: `Başlık`,
			dataIndex: ['title', 'tr'],
		},
		{
			title: `Kategori`,
			dataIndex: ['category', 'title', ['tr']],
		},
		{
			title: `Yayın Türü`,
			dataIndex: 'publicationType',
		},
		{
			title: 'Kitay Tipi',
			dataIndex: 'workType',
		},
		{
			title: 'Beğeni Sayısı',
			dataIndex: 'likesCount',
		},
		{
			title: 'Okunma Sayısı',
			dataIndex: 'readingCount',
		},
		{
			title: 'Yorum Sayısı',
			dataIndex: 'commentCount',
		},
		{
			title: 'Değerlendirme Sayısı',
			dataIndex: 'totalRatingCount',
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Görüntüle'}>
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								// showWorkDetail(elm);
								history.push(`/works/${elm.id}`);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteWork(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const fetchBooksTotal = () => {
		instanceApi()
			.get('/works?limit=20')
			.then(res => {
				setTotal(res.data.totalResults);
				setData(res.data.results);
			})
			.catch(err => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchBooksTotal();
	}, []);

	const closeVisible = () => {
		setVisible(false);
	};

	const rowSelection = {
		onChange: selectedRowKeys => {
			setButtonVisible(true);
			//console.log("selectedKeys: ", selectedRowKeys);
			selectedRowKeys.map(selectedRow => {
				if (!tempWorks.includes(selectedRow)) {
					tempWorks.push(selectedRow);
				}
			});
			setSelectedRows(selectedRowKeys);
		},
	};

	const addButtonOnClick = () => {
		setModalVisible(true);
		selectedRows.map(selectedRow => {
			if (!tempWorks.includes(selectedRow)) {
				tempWorks.push(selectedRow);
			}
		});
		console.log('tempWorks: ', tempWorks);

		tempWorks.map((tempWork, index) => {
			const data = {
				order: index + 1,
				work: tempWork,
			};
			works.push(data);
		});
	};

	const handleCancel = () => {
		setModalVisible(false);
	};

	const onFinish = values => {
		setLoading(true);

		const data = {
			title: values.title,
			description: values.description,
			order: values.order,
			isActive: true,
			works: works,
		};

		createHomeList(data)
			.then(res => {
				setLoading(false);
				notification.success({
					message: 'Liste başarılı bir şekilde oluşturuldu!',
				});
				setModalVisible(false);
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
				notification.error({
					message: err.message,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const paginationOnChange = event => {
		selectedRows.map(selectedRow => {
			if (!tempWorks.includes(selectedRow)) {
				tempWorks.push(selectedRow);
			}
		});
		instanceApi()
			.get('/works?page=' + event)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log(err));
	};

	const filterFormOnFinish = values => {
		instanceApi()
			.get('/works/search?query=' + values.search)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log('err', err));
	};

	const filterFormOnFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	return (
		<>
			<Modal
				title="Anasayfa Listesi İçin Gruplandırma Yap"
				visible={modalVisible}
				onCancel={handleCancel}
				footer={null}>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="title"
						label="Başlık"
						rules={[
							{
								required: true,
								message: 'Lütfen Başlık alanını girin.',
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="description"
						label="Açıklama"
						rules={[
							{
								required: true,
								message: 'Lütfen açıklama alanını girin.',
							},
						]}>
						<TextArea rows={'5'} />
					</Form.Item>

					<Form.Item
						name="order"
						label="Sıra"
						rules={[
							{
								required: true,
								message: 'Lütfen sıra alanını girin.',
							},
						]}>
						<InputNumber style={{ width: '100%' }} />
					</Form.Item>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '200px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</Modal>

			<FilterEpisode>
				<Card>
					<Form
						layout="vertical"
						name="filter-form"
						onFinish={filterFormOnFinish}
						onFinishFailed={filterFormOnFinishFailed}>
						<FilterFormLine>
							<Form.Item
								name="search"
								label="Eser İsmine Göre Ara"
								style={{ width: '300px' }}
								rules={[
									{
										required: false,
										message: 'Lütfen Arama alanını girin',
									},
								]}>
								<Input />
							</Form.Item>
							<FilterSearchButtonEpisode>
								<Form.Item>
									<ButtonSections>
										<PrimaryButton>
											<Button
												style={{ width: '180px' }}
												type="default"
												htmlType="submit"
												loading={filterLoading}>
												Ara
											</Button>
										</PrimaryButton>
									</ButtonSections>
								</Form.Item>
							</FilterSearchButtonEpisode>
						</FilterFormLine>
					</Form>
				</Card>
			</FilterEpisode>

			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						rowSelection={rowSelection}
						rowKey="id"
						columns={columns}
						dataSource={data}
						pagination={false}
					/>

					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>

					<ButtonEpisode>
						{buttonVisible && (
							<>
								<Button
									style={{ width: '120px' }}
									type="primary"
									onClick={() => addButtonOnClick()}
									block>
									Ekle
								</Button>
							</>
						)}
					</ButtonEpisode>
				</div>
				<WorkDraver
					visible={visible}
					close={closeVisible}
					title={title}
					introduction={introduction}
					slug={slug}
					publicationType={publicationType}
					isActive={isActive}
					likesCount={likesCount}
					totalRating={totalRating}
					totalRatingCount={totalRatingCount}
					workType={workType}
					categoryTitle={categoryTitle}
					publishDate={publishDate}
					creatorFullName={creatorFullName}
					creatorUserName={creatorUserName}
					createdAt={createdAt}
					id={id}
				/>
			</Card>
		</>
	);
};
export default WorkTable;
