import { $axios } from '../Base/base.services';

/**
 *
 * @param user
 * @returns {Promise<unknown>}
 */
export const createUser = user =>
	new Promise((resolve, reject) => {
		$axios
			.post('/users', {
				fullname: user.fullname,
				email: user.email,
				username: user.username,
				password: user.password,
				role: user.role,
			})
			.then(res => {
				resolve(res);
			})
			.catch(error => {
				console.log('error:', error);
				reject(error.response.data);
			});
	});

/**
 *
 * @param user
 * @param id
 * @returns {Promise<unknown>}
 */
export const updateUser = (user,id) =>
	new Promise((resolve, reject) => {
		$axios
			.patch('/users/' + id,{
				fullname: user.fullname,
				email: user.email,
				username: user.username,
				password: user.password,
				role: user.role
			})
			.then(res => {
				resolve(res);
			})
			.catch(error => {
				console.log('error:', error);
				reject(error.response.data);
			});
	});

/**
 *
 * @param id
 * @returns {Promise<unknown>}
 */
export const showUserAsPageActive = (id) =>
	new Promise((resolve, reject) => {
		$axios
			.patch('/users/' + id + '/manage', {
				hasPublishedAtShowcase: true
			})
			.then(res => {
				resolve(res);
			})
			.catch(error => {
				console.log('error:', error);
				//reject(error.response.data);
			})
	});

/**
 *
 * @param id
 * @returns {Promise<unknown>}
 */
export const showUserAsPageInactive = (id) =>
	new Promise((resolve, reject) => {
		$axios
			.patch('/users/' + id + '/manage', {
				hasPublishedAtShowcase: false
			})
			.then(res => {
				resolve(res);
			})
			.catch(error => {
				console.log(error);
				//reject(error.response.data);
			})
	})