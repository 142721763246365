import React, {useEffect, useState} from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import {
	EditOutlined,
	SettingOutlined,
	LogoutOutlined,
} from '@ant-design/icons';
import { instanceApi } from "../../../../services/Base/base.instance.services";
import styled from "styled-components";

const menuItem = [
	{
		title: 'Profile Settings',
		icon: EditOutlined,
		path: '#',
	},
	{
		title: 'Password Setting',
		icon: SettingOutlined,
		path: '#',
	}
];

const signOut = () => {
	localStorage.removeItem('token');
	window.location = '/';
};

const Section = styled.div`
	margin-left: 10px;
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NavProfile = () => {

	const [ userName, setUsername ] = useState();
	const [ fullName, setFullName ]	= useState();

	useEffect(() => {
		fetchUserInfo();
	},[]);

	const fetchUserInfo = () => {
		instanceApi()
			.get('/users/' + localStorage.getItem('userId'))
			.then(res => {
				setUsername(res.data.username);
				setFullName(res.data.fullname);
			})
			.catch(err => console.log(err));
	}

	const profileImg = '/img/avatar.png';

	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<Avatar size={45} src={profileImg} />
					<Section>
						<h4 className="mb-0">{userName}</h4>
						<span className="text-muted">{fullName}</span>
					</Section>
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu>
					<Menu.Item
						key={menuItem.length + 1}
						onClick={e => signOut()}>
						<span>
							<LogoutOutlined className="mr-3" />
							<span className="font-weight-normal">Çıkış Yap</span>
						</span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	);
	return (
		<Dropdown
			placement="bottomRight"
			overlay={profileMenu}
			trigger={['click']}>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item>
					<Avatar src={profileImg} />
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};
export default NavProfile;
