import React, { useEffect, useState } from "react";
import {Button, Card, Table, Tooltip, Modal, notification, Pagination} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from "../../../../services/Base/base.instance.services";
import { SystemUserDraver } from "../../../molecules/Draver";

const SystemUserTable = () => {

    const [ data, setData ]             = useState();
    const [ visible, setVisible ]       = useState(false);
    const [ role, setRole ]             = useState();
    const [ fullName, setFullName ]     = useState();
    const [ username, setUsername ]     = useState();
    const [ email, setEmail ]           = useState();
    const [ createdAt, setCreatedAt ]   = useState();
    const [ gender, setGender ]         = useState();
    const [ id, setId]                  = useState();
    const [ total, setTotal ]           = useState();

    const { confirm } = Modal;

    const showUserDetail = (user) => {
        setVisible(true);
        setRole(user.role);
        setFullName(user.fullname);
        setUsername(user.username);
        setEmail(user.email);
        setCreatedAt(user.createdAt);
        setGender(user.gender);
        setId(user.id);
    }

    const deleteUserProfile = (userId) => {
        confirm({
            title: `Bu kullanıcıyı silmek istediğinize emin misiniz?`,
            okText: `Evet`,
            okType: 'danger',
            cancelText: `Hayır`,
            onOk() {
                instanceApi()
                    .delete('/users/' + userId)
                    .then(res => {
                        window.location = '/users';
                    })
                    .catch(err => {
                        console.log("err: ", err);
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const editUserProfile = (userId) => {
        window.location = '/user/edit/' + userId;
    }

    const columns = [
        {
            title: `İsim`,
            dataIndex: 'fullname',
        },
        {
            title: `Kullanıcı Adı`,
            dataIndex: 'username',
        },
        {
            title: `Email`,
            dataIndex: 'email',
        },
        {
            title: `Rol`,
            dataIndex: 'role',
        },
        {
            "title": "Tarih",
            dataIndex: 'createdAt'
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (_, elm) => (
                <div className="text-right d-flex justify-content-end">
                    <Tooltip title={'Düzenle'}>
                        <Button
                            style={{ marginRight: 10}}
                            className="mr-5"
                            icon={<EditOutlined />}
                            onClick={() => {
                                editUserProfile(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title={'Görüntüle'}>
                        <Button
                            type="primary"
                            className="mr-2"
                            icon={<EyeOutlined />}
                            onClick={() => {
                                showUserDetail(elm);
                            }}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title={'Sil'}>
                        <Button
                            style={{ marginLeft: '12px' }}
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                deleteUserProfile(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const fetchUsers = () => {
        instanceApi()
            .get('/users?role=admin')
            .then(res => {
                setData(res.data.results);
                setTotal(res.data.totalResults);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        fetchUsers();
    },[]);

    const closeVisible = () => {
        setVisible(false);
    }

    const paginationOnChange = (event) => {
        instanceApi()
            .get('/users?role=admin&page=' + event)
            .then(res => {
                setData(res.data.results);
            })
            .catch(err => console.log(err));
    }

    return(
        <>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={data} pagination={false}/>
                    <Pagination
                        style={{ padding: "15px"}}
                        defaultCurrent={1}
                        defaultPageSize={10}
                        total={total}
                        onChange={paginationOnChange}
                    />
                </div>
                <SystemUserDraver
                    visible={visible}
                    close={closeVisible}
                    role={role}
                    fullname={fullName}
                    username={username}
                    email={email}
                    createdAt={createdAt}
                    gender={gender}
                    id={id}
                />
            </Card>
        </>
    )
}
export default SystemUserTable