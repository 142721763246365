import { $axios } from '../Base/base.services';

/**
 *
 * @param formData
 * @param categoryId
 * @returns {Promise<unknown>}
 */
export const createHomeList = data =>
	new Promise((resolve, reject) => {
		$axios
			.post('/home-lists', {
				title: data.title,
				description: data.description,
				order: data.order,
				isActive: data.isActive,
				works: data.works,
			})
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('error:', err);
				reject(err.response.data);
			});
	});

export const updateHomeList = (id, data) =>
	new Promise((resolve, reject) => {
		$axios
			.put('/home-lists/' + id, {
				works: data,
			})
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	});

export const updateHomeListDetails = (id, data) =>
	new Promise((resolve, reject) => {
		$axios
			.put('/home-lists/' + id, {
				...data,
			})
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	});

export const updateOrderToWorkService = (homeListId, workId, order) =>
	new Promise((resolve, reject) => {
		$axios
			.patch('/home-lists/' + homeListId + '/works/' + workId, {
				order: order,
			})
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	});
