import { $axios } from "../Base/base.services";

/**
 *
 * @param formData
 * @param avatarId
 * @returns {Promise<unknown>}
 */
export const updateAvatar = (formData, avatarId) =>
    new Promise((resolve, reject) => {
        $axios
            .patch('/avatars/' + avatarId, formData)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log('error:', err);
                reject(err.response.data);
            })
    })

/**
 *
 * @param formData
 * @returns {Promise<unknown>}
 */
export const createAvatar = (formData) =>
    new Promise((resolve, reject) => {
        $axios
            .post('/avatars/', formData)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log('error:', err);
                reject(err.response.data);
            })
    })