import React from 'react';
import styled from 'styled-components';
import { device } from '../../../utils/device';
import theme from '../../../theme';
import HomeListInfoCard from '../../molecules/InfoCard/HomeListInfoCard';
import { Button } from 'antd';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
	display: flex;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSection = styled.div`
	display: block;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Title = styled.div``;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleText = styled.h2`
	font-size: 29px;
	color: ${theme.darkBlue};

	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescription = styled.div``;

/**
 *
 * @type {StyledComponent<"p", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescriptionText = styled.label`
	font-size: 15px;
	color: ${theme.lightBlue};
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonEpisode = styled.div`
	margin-left: auto;

	@media ${device.ipad} {
		margin-right: 15px;
	}

	@media ${device.mobileL} {
		margin-right: 15px;
		margin-top: 10px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ContentSection = styled.div`
	margin-top: 20px;

	@media ${device.ipad} {
		margin-right: 15px;
	}

	@media ${device.mobileL} {
		margin-right: 15px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSection = styled.div`
	margin-left: auto;

	@media ${device.ipadL} {
		margin-right: 15px;
	}
`;

const HomeListEdit = ({ id }) => {
	return (
		<>
			<Container>
				<TopSection>
					<TitleSection>
						<Title>
							<TitleText>Anasayfa Listeleri Düzenle</TitleText>
						</Title>
						<TitleDescription>
							<TitleDescriptionText>
								Bu sayfa üzerinden okuma listelerini
								düzenleyebilirsiniz.
							</TitleDescriptionText>
						</TitleDescription>
					</TitleSection>
					<ButtonSection>
						<Button
							type="primary"
							onClick={() => {
								window.location = '/create/work/' + id;
							}}
							htmlType="submit">
							Eser Ekle
						</Button>
					</ButtonSection>
				</TopSection>
				<ContentSection>
					<HomeListInfoCard id={id} />
				</ContentSection>
			</Container>
		</>
	);
};
export default HomeListEdit;

