import { DeleteOutlined, ScissorOutlined } from '@ant-design/icons';

import { Button, Card, Modal, Pagination, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { instanceApi } from '../../../../services/Base/base.instance.services';

// Define an object that maps status values to styles
const statusStyles = {
	waiting: {
		backgroundColor: '#cfcf47',
		color: 'black',
	},
	approved: {
		backgroundColor: '#21a621',
		color: 'white',
	},
	rejected: {
		backgroundColor: '#ca4141',
		color: 'white',
	},
};

const StatusWrapper = styled.div`
	background-color: ${props =>
		statusStyles[props.status]?.backgroundColor || 'blue'};
	border-radius: 50px;
	text-align: center;
	border: 1px solid #999;
	color: ${props => statusStyles[props.status]?.color || 'black'};
`;
const AvatartWrapper = styled.div`
	border-radius: 100%;
	width: 40px;
	height: 40px;
	overflow: hidden;
`;
const AvatarImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const ChatBotTable = () => {
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const [role, setRole] = useState();
	const [fullName, setFullName] = useState();
	const [username, setUsername] = useState();
	const [email, setEmail] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [gender, setGender] = useState();
	const [id, setId] = useState();
	const [total, setTotal] = useState();
	const [filterLoading, setFilterLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	const { confirm } = Modal;
	const statusTypesLookUp = {
		waiting: 'Beklemede',
		approved: 'Onaylandı',
		rejected: 'Reddedildi',
	};

	const showUserDetail = user => {
		setVisible(true);
		setRole(user.role);
		setFullName(user.fullname);
		setUsername(user.username);
		setEmail(user.email);
		setCreatedAt(user.createdAt);
		setGender(user.gender);
		setId(user.id);
	};

	const getAllChatBotsFromServer = (page = 1) => {
		instanceApi()
			.get('/chatbots?limit=10&page=' + page)
			.then(res => {
				console.log(res.data);
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		//Gel all
		getAllChatBotsFromServer();
	}, []);

	//Deletes
	const handleOnChatBotDeleted = item => {
		confirm({
			title: `Silmek istedigine emin misin?`,

			okText: `Evet`,
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/chatbots/' + item.id)
					.then(res => {
						getAllChatBotsFromServer();
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const paginationOnChange = event => {
		getAllChatBotsFromServer(event);
	};

	const columns = [
		{
			title: ``,
			dataIndex: ['avatar'],

			render: avatar => {
				return (
					<AvatartWrapper>
						<AvatarImage
							src={avatar}
							alt="avatar"
							style={{ width: '100%' }}
						/>
					</AvatartWrapper>
				);
			},
		},
		{
			title: `İsim`,
			dataIndex: ['name', 'tr'],
		},
		{
			title: 'Model',
			dataIndex: 'model',
		},
		{
			title: 'Asistan Id',
			dataIndex: 'assistantId',
		},

		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},

		{
			title: 'Durum',
			dataIndex: 'isActive',
			render: isActive => {
				return <span>{isActive ? 'Aktif' : 'Pasif'}</span>;
			},
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Duzenle'}>
						<Button
							style={{
								marginRight: 10,
								backgroundColor: '#5cb2ce',
								color: 'white',
							}}
							className="mr-5"
							icon={<ScissorOutlined />}
							onClick={() => {
								window.location = '/edit/chatbot/' + elm.id;
							}}
							size="small">
							Duzenle
						</Button>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							type="danger"
							className="mr-2"
							icon={<DeleteOutlined style={{ fontSize: 18 }} />}
							onClick={() => {
								handleOnChatBotDeleted(elm);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
			</Card>
		</>
	);
};
export default ChatBotTable;
