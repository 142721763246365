import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { instanceApi } from '../../../services/Base/base.instance.services';
import {
	Button,
	Form,
	Input,
	message,
	Modal,
	notification,
	Select,
	Switch,
} from 'antd';
import { device } from '../../../utils/device';
import { Option } from 'antd/es/mentions';

import {
	updateHomeList,
	updateHomeListDetails,
	updateOrderToWorkService,
} from '../../../services/HomeList';

const HomeListHeaderEpisode = styled.div`
	width: 100%;
	padding: 20px;
	border-radius: 8px;
	background-color: #eeeeee;
`;

const HomeListHeaderLineEpisode = styled.div`
	margin-bottom: 1rem;
	gap: 3rem;
	display: flex;
	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
`;

const HomeListLeftEpisode = styled.div`
	width: 50%;
	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;

const HomeListRightEpisode = styled.div`
	margin-left: 10px;
	width: 50%;
	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;

const HomeListTitleSection = styled.div``;

const HomeListDescriptionSection = styled.div``;

const WorksSectionBox = styled.div`
	margin-top: 20px;
	padding: 20px;
	border-radius: 8px;
	background-color: white;
`;

const WorksLineEpisode = styled.div`
	display: flex;
`;

const WorksLeftEpisode = styled.div`
	width: 33%;
`;

const WorksRightEpisode = styled.div`
	width: 33%;
	margin-left: 10px;
`;

const WorksTitleEpisode = styled.div``;

const WorksDescriptionEpisode = styled.div``;

const ButtonsEpisode = styled.div`
	margin-top: 10px;
	display: flex;
`;

const DeleteButtonEpisode = styled.div`
	margin-left: auto;
`;

const UpdateOrderButtonEpisode = styled.div`
	margin-left: 10px;
`;

const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;

const PrimaryButton = styled.div`
	margin-left: auto;
	margin-top: 10px;
`;

const HomeListInfoCard = ({ id }) => {
	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [isActive, setIsActive] = useState();
	const [getLanguage, setLanguage] = useState();
	const [tag, setTag] = useState("");
	const [order, setOrder] = useState();
	const [works, setWorks] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [tempWorks, setTempWorks] = useState([]);
	const [workId, setWorkId] = useState();
	const [updateOrderModalVisible, setUpdateOrderModalVisible] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [autoUpdate, setAutoUpdate] = useState(false);
	const [workType, setWorkType] = useState('');
	const [category, setCategory] = useState('');
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		instanceApi()
			.get('/home-lists/' + id)
			.then(res => {
				setTitle(res.data.title);
				setLanguage(res.data.language);
				setDescription(res.data.description);
				setIsActive(res.data.isActive);
				setOrder(res.data.order);
				setWorks(res.data.works);
				setAutoUpdate(res.data.autoUpdate);
				setTag(res.data.tag)
				if (res.data.workType) setWorkType(res.data.workType);
				if (res.data.category) setCategory(res.data.category);
			})
			.catch(err => {
				console.log('err: ', err);
			});
		instanceApi()
			.get('/categories?limit=1000')
			.then(res => {
				setCategories(res.data.results);
			})
			.catch(err => console.log(err));
	}, []);

	const removeWorkHandleClick = work => {
		let remove = work;
		let index = works.indexOf(remove);
		if (index > -1) {
			works.splice(index, 1);
		}
		setModalVisible(true);
	};

	const handleCancel = () => {
		setModalVisible(false);
	};

	const updateOrderModalHandleCancel = () => {
		setUpdateOrderModalVisible(false);
	};

	const deleteWorkHandleClick = () => {
		works.map(work => {
			const data = {
				order: work.order,
				work: work.work.id,
			};
			tempWorks.push(data);
		});

		updateHomeList(id, tempWorks)
			.then(res => {
				window.location = '/home-lists/' + id;
			})
			.catch(err => console.log('err: ', err));
	};

	const updateOrderToWork = work => {
		setWorkId(work.work.id);
		setUpdateOrderModalVisible(true);
	};

	const onFinish = values => {
		setLoading(true);
		updateOrderToWorkService(id, workId, values.order)
			.then(res => {
				setLoading(false);
				window.location = '/home-lists/' + id;
			})
			.catch(err => {
				console.log('err: ', err);
				setLoading(false);
			});
	};

	const onFinishFailed = errorInfo => {
		setLoading(false);
		notification.error({
			message: `Bilgilerinizi kontrol edip tekrar deneyin!`,
		});
	};

	const onUpdate = async () => {
		try {
			await updateHomeListDetails(id, {
				title,
				description,
				isActive,
				language: getLanguage,
				order,
				autoUpdate,
				workType,
				category,
				tag
			});
			message.success('Bilgiler kaydedildi.');
		} catch (error) {
			message.success('Bir hata oluştu.');
		}
	};

	return (
		<>
			<Modal
				title="Anasayfa Listesi İçin Eser Kaldır"
				visible={modalVisible}
				onCancel={handleCancel}
				footer={null}>
				<h5>
					Bu eseri okuma listesinden kaldırmak istediğinize
					eminmisiniz?
				</h5>
				<ButtonSections>
					<PrimaryButton>
						<Button
							style={{ width: '80px' }}
							type="primary"
							htmlType="submit"
							onClick={deleteWorkHandleClick}>
							Kaldır
						</Button>
					</PrimaryButton>
				</ButtonSections>
			</Modal>

			<Modal
				title="Eser Sırası Güncelle"
				visible={updateOrderModalVisible}
				onCancel={updateOrderModalHandleCancel}
				footer={null}>
				<h5>Bu eserin sıra numarasını değiştir.</h5>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="order"
						label="Sıra"
						rules={[
							{
								required: true,
								message: 'Lütfen Sıra girin.',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item>
						<ButtonsEpisode>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type={'primary'}
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
						</ButtonsEpisode>
					</Form.Item>
				</Form>
			</Modal>
			<HomeListHeaderEpisode>
				<HomeListHeaderLineEpisode>
					<HomeListLeftEpisode>
						<HomeListTitleSection>
							<h4>Başlık</h4>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Input
								type="text"
								value={title}
								onChange={e => setTitle(e.target.value)}
							/>
						</HomeListDescriptionSection>
					</HomeListLeftEpisode>
					<HomeListRightEpisode>
						<HomeListTitleSection>
							<h4>Açıklama</h4>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Input
								type="text"
								value={description}
								onChange={e => setDescription(e.target.value)}
							/>
						</HomeListDescriptionSection>
					</HomeListRightEpisode>
				</HomeListHeaderLineEpisode>
				<HomeListHeaderLineEpisode>
					<HomeListLeftEpisode>
						<HomeListTitleSection>
							<h4>Durum</h4>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Switch
								checkedChildren="Aktif"
								unCheckedChildren="Pasif"
								checked={isActive}
								onChange={e => setIsActive(e)}
							/>
						</HomeListDescriptionSection>
					</HomeListLeftEpisode>
					<HomeListRightEpisode>
						<HomeListTitleSection>
							<h4>Sıra</h4>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Input
								type="number"
								value={order}
								onChange={e => setOrder(e.target.value)}
							/>
						</HomeListDescriptionSection>
					</HomeListRightEpisode>
				</HomeListHeaderLineEpisode>
				<HomeListHeaderLineEpisode>
					<HomeListLeftEpisode>
						<HomeListTitleSection>
							<h4>Otomatik Güncelleme</h4>
							<p>
								Eğer aktif olursa, seçilen kategori ve/veya eser
								tipine göre her hafta en beğenilen eserleri bu
								listede sunar
							</p>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Switch
								checkedChildren="Aktif"
								unCheckedChildren="Pasif"
								checked={autoUpdate}
								onChange={e => setAutoUpdate(e)}
							/>
						</HomeListDescriptionSection>
					</HomeListLeftEpisode>
					<HomeListRightEpisode>
						<HomeListTitleSection>
							<h4>Liste Kategori</h4>
							<p>Otomatik güncellemede kullanılacak kategori</p>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Select
								className="w-full"
								value={category}
								onChange={setCategory}>
								<Option value={''}>Yok</Option>
								{categories?.map(category => {
									return (
										<Select.Option value={category.id}>
											{category?.title?.tr}
										</Select.Option>
									);
								})}
							</Select>
						</HomeListDescriptionSection>
					</HomeListRightEpisode>
				</HomeListHeaderLineEpisode>

				<HomeListHeaderLineEpisode>
					<HomeListLeftEpisode>
						<HomeListTitleSection>
							<h4>Liste Eser Tipi</h4>
							<p>Otomatik güncellemede kullanılacak eser tipi</p>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Select
								className="w-full"
								value={workType}
								onChange={setWorkType}>
								<Select.Option value={''}>Tümü</Select.Option>
								<Select.Option value={'drawing'}>
									Çizgi
								</Select.Option>
								<Select.Option value={'novel'}>
									Roman
								</Select.Option>
							</Select>
						</HomeListDescriptionSection>
					</HomeListLeftEpisode>
					<HomeListRightEpisode>
						<HomeListTitleSection>
							<h4>Dil</h4>
							<p>Slider dili</p>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Select
								className="w-full"
								value={getLanguage}
								onChange={setLanguage}>
								{[
									{ title: 'English', slug: 'en' },
									{ title: 'Turkce', slug: 'tr' },
								]?.map(lang => {
									return (
										<Select.Option value={lang.slug}>
											{lang.title}
										</Select.Option>
									);
								})}
							</Select>
						</HomeListDescriptionSection>
					</HomeListRightEpisode>
				</HomeListHeaderLineEpisode>
				<HomeListHeaderLineEpisode>
					<HomeListLeftEpisode>
						<HomeListTitleSection>
							<h4>Liste Tagi</h4>
							<p>Otomatik güncellemede kullanılacak tag</p>
						</HomeListTitleSection>
						<HomeListDescriptionSection>
							<Input
								type="text"
								value={tag}
								onChange={e => setTag(e.target.value)}
							/>
						</HomeListDescriptionSection>
					</HomeListLeftEpisode>
				</HomeListHeaderLineEpisode>
				<PrimaryButton>
					<Button
						style={{ width: '80px' }}
						type="primary"
						htmlType="submit"
						onClick={onUpdate}>
						Kaydet
					</Button>
				</PrimaryButton>
			</HomeListHeaderEpisode>

			{works.map(work => (
				<>
					<WorksSectionBox>
						<WorksLineEpisode>
							<WorksLeftEpisode>
								<WorksLeftEpisode>
									<WorksTitleEpisode>
										<h5>Başlık</h5>
									</WorksTitleEpisode>
									<WorksDescriptionEpisode>
										<p>{work?.work?.title?.tr}</p>
									</WorksDescriptionEpisode>
								</WorksLeftEpisode>
							</WorksLeftEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Açıklama</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.introduction?.tr}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Sıra</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.order}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
						</WorksLineEpisode>

						<WorksLineEpisode>
							<WorksLeftEpisode>
								<WorksLeftEpisode>
									<WorksTitleEpisode>
										<h5>Slug</h5>
									</WorksTitleEpisode>
									<WorksDescriptionEpisode>
										<p>{work?.work?.slug?.tr}</p>
									</WorksDescriptionEpisode>
								</WorksLeftEpisode>
							</WorksLeftEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Yayınlanma Tipi</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.publicationType}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Durum</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>
										{work?.work?.isActive
											? 'Aktif'
											: 'Pasif'}
									</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
						</WorksLineEpisode>

						<WorksLineEpisode>
							<WorksLeftEpisode>
								<WorksLeftEpisode>
									<WorksTitleEpisode>
										<h5>Beğeni Sayısı</h5>
									</WorksTitleEpisode>
									<WorksDescriptionEpisode>
										<p>{work?.work?.likesCount}</p>
									</WorksDescriptionEpisode>
								</WorksLeftEpisode>
							</WorksLeftEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Toplam Raiting</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.totalRating}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Toplam Raiting Sayısı</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.totalRatingCount}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
						</WorksLineEpisode>

						<WorksLineEpisode>
							<WorksLeftEpisode>
								<WorksLeftEpisode>
									<WorksTitleEpisode>
										<h5>Okuma Sayısı</h5>
									</WorksTitleEpisode>
									<WorksDescriptionEpisode>
										<p>{work?.work?.readingCount}</p>
									</WorksDescriptionEpisode>
								</WorksLeftEpisode>
							</WorksLeftEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Yorum Sayısı</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.commentCount}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Raiting</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.rating}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
						</WorksLineEpisode>

						<WorksLineEpisode>
							<WorksLeftEpisode>
								<WorksLeftEpisode>
									<WorksTitleEpisode>
										<h5>Yayınlanma Tarihi</h5>
									</WorksTitleEpisode>
									<WorksDescriptionEpisode>
										<p>{work?.work?.publishDate}</p>
									</WorksDescriptionEpisode>
								</WorksLeftEpisode>
							</WorksLeftEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Tags</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.tags}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Tip</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.workType}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
						</WorksLineEpisode>

						<WorksLineEpisode>
							<WorksLeftEpisode>
								<WorksLeftEpisode>
									<WorksTitleEpisode>
										<h5>Cover</h5>
									</WorksTitleEpisode>
									<WorksDescriptionEpisode>
										<img
											style={{ width: '300px' }}
											src={work?.work?.cover}
										/>
									</WorksDescriptionEpisode>
								</WorksLeftEpisode>
							</WorksLeftEpisode>
							<WorksRightEpisode>
								<WorksTitleEpisode>
									<h5>Tarih</h5>
								</WorksTitleEpisode>
								<WorksDescriptionEpisode>
									<p>{work?.work?.createdAt}</p>
								</WorksDescriptionEpisode>
							</WorksRightEpisode>
						</WorksLineEpisode>

						<ButtonsEpisode>
							<DeleteButtonEpisode>
								<Button
									style={{ width: '80px' }}
									onClick={() => removeWorkHandleClick(work)}
									type="primary">
									Kaldır
								</Button>
							</DeleteButtonEpisode>
							<UpdateOrderButtonEpisode>
								<Button
									onClick={() => updateOrderToWork(work)}
									type="default">
									Sıra Güncelle
								</Button>
							</UpdateOrderButtonEpisode>
						</ButtonsEpisode>
					</WorksSectionBox>
				</>
			))}
		</>
	);
};
export default HomeListInfoCard;
