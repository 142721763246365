import React from 'react';
import { size } from './size';

/**
 *
 * @type {{tablet: string, desktopL: string, desktop: string, chrome: string, laptopL: string, mobileS: string, ipad: string, mobileM: string, mobileL: string, ipadL: string}}
 */
export const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    ipad: `(max-width: ${size.ipad})`,
    ipadL: `(max-width: ${size.ipadL})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
    chrome: `(max-width: ${size.chrome})`,
};