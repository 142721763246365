import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import { createUser } from '../../../services/User';
import {device} from "../../../utils/device";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;
	
	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;
	
	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CreateNewUserForm = () => {
	const [loading, setLoading] 				= useState(false);
	const [ roleTypeValue, setRoleTypeValue ]	= useState();

	const roleTypeValueOnChange = event => {
		setRoleTypeValue(event);
	}

	const onFinish = values => {
		setLoading(true);

		const user = {
			fullname: values.fullname,
			email: values.email,
			username: values.username,
			password: values.password,
			role: values.role
		};

		createUser(user)
			.then(res => {
				setLoading(false);
				window.location = '/users';
			})
			.catch(err => {
				setLoading(false);
				console.log('error: ', err);
				notification.error({
					message: err.message
				})
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Lütfen girdiğiniz bilgileri kontrol edip tekrar deneyin!`,
		});
	};

	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>

					<Form.Item
						name="fullname"
						label="İsim Soyisim"
						rules={[
							{
								required: true,
								message: 'Lütfen isim soyisim alanını giriniz.',
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="email"
						label="Email"
						rules={[
							{
								required: true,
								message: 'Lütfen Email alanını girin.',
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="username"
						label="Kullanıcı Adı"
						rules={[
							{
								required: true,
								message: 'Lütfen Kullanıcı Adı alanını girin.',
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="password"
						label="Password"
						rules={[
							{
								required: true,
								message: 'Lütfen Şifre alanını girin.',
							},
						]}>
						<Input.Password/>
					</Form.Item>

					<Form.Item
						name="role"
						label="Rol"
						rules={[
							{
								required: true,
								message: 'Lütfen Rol Seçin.',
							},
						]}>
						<Select
							value={roleTypeValue}
							onChange={roleTypeValueOnChange}>
							<Option value={'user'}>Normal Kullanıcı</Option>
							<Option value={'admin'}>Admin Kullanıcısı</Option>
						</Select>
					</Form.Item>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/users'}
									htmlType="submit">
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};
export default CreateNewUserForm;
