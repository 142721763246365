import React, { useState } from 'react';
import { Button, Form, Input, Row, Col, notification } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { login } from '../../../../services/Auth/UserAuth';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LoginForm = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const onFinish = values => {
		setLoading(true);

		const user = {
			email: values.email,
			password: values.password,
		};

		login(user)
			.then(res => {
				if (!res) {
					setLoading(false);
				}
				if (res) {
					setLoading(false);
					window.location = '/dashboard';
				}
			})
			.catch(err => {
				console.log('err: ', err);
				setLoading(false);
				notification.error({
					message: `Lütfen bilgilerinizi kontrol ediniz!`,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('error: ', errorInfo);
	};

	return (
		<>
			<Col xs={20} sm={20} md={24} lg={16}>
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="center">
						<Col xs={24} sm={24} md={20} lg={12} xl={8}>
							<div className="mt-4">
								<Form
									layout="vertical"
									name="login-form"
									onFinish={onFinish}
									onFinishFailed={onFinishFailed}>
									<Form.Item
										name="email"
										label={
											<div
												className={`'d-flex justify-content-between w-100 align-items-center' : ''}`}>
												<span>Email</span>
											</div>
										}
										rules={[
											{
												required: true,
												message:
													'Lütfen Email alanını girin.',
											},
											{
												type: 'email',
												message:
													'Lütfen geçerli bir email girin.',
											},
										]}>
										<Input
											prefix={
												<MailOutlined className="text-primary" />
											}
										/>
									</Form.Item>
									<Form.Item
										name="password"
										label={
											<div
												className={`'d-flex justify-content-between w-100 align-items-center' : ''}`}>
												<span>Şifre</span>
											</div>
										}
										rules={[
											{
												required: true,
												message:
													'Lütfen Şifre alanını girin.',
											},
										]}>
										<Input.Password
											prefix={
												<LockOutlined className="text-primary" />
											}
										/>
									</Form.Item>
									<Form.Item>
										<Button
											type="primary"
											htmlType="submit"
											block
											loading={loading}>
											Giriş
										</Button>
									</Form.Item>
								</Form>
							</div>
						</Col>
					</Row>
				</div>
			</Col>
		</>
	);
};

export default LoginForm;
