import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Pagination,
} from 'antd';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ReportDraver } from '../../Draver';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsTable = () => {
	const [data, setData] = useState([]);
	const [userRole, setUserRole] = useState();
	const [followersCount, setFollowersCount] = useState();
	const [userFullName, setUserFullName] = useState();
	const [userName, setUserName] = useState();
	const [email, setEmail] = useState();
	const [message, setMessage] = useState();
	const [type, setType] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [id, setId] = useState();
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [total, setTotal] = useState();
	const [selected, setSelected] = useState(null);

	const showReportDetail = report => {
		setDrawerVisible(true);
		setSelected(report);
		setUserRole(report.user.role);
		setFollowersCount(report.user.followersCount);
		setUserFullName(report.user.fullname);
		setUserName(report.user.username);
		setEmail(report.user.email);
		setMessage(report.message);
		setType(report.type);
		setCreatedAt(report.createdAt);
		setId(report.id);
	};

	const columns = [
		{
			title: `Id`,
			dataIndex: 'id',
		},
		{
			title: `Tip`,
			dataIndex: 'type',
		},
		{
			title: `Kullanıcı`,
			dataIndex: ['user', 'fullname'],
		},
		{
			title: `Kullanıcı Rol`,
			dataIndex: ['user', 'role'],
		},
		{
			title: `Kullanıcı Adı`,
			dataIndex: ['user', 'username'],
		},
		{
			title: `Özne`,
			dataIndex: ['sub'],
		},
		{
			title: `Mesaj`,
			dataIndex: ['message'],
		},
		{
			title: `Tarih`,
			dataIndex: ['createdAt'],
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title="Göster">
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								showReportDetail(elm);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const fetchReports = () => {
		instanceApi()
			.get('/reports?sortBy=createdAt:desc')
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		fetchReports();
	}, []);

	const closeVisible = () => {
		setDrawerVisible(false);
	};

	const paginationOnChange = event => {
		instanceApi()
			.get('/reports?sortBy=createdAt:desc&page=' + event)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log(err));
	};

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
				<ReportDraver
					visible={drawerVisible}
					close={closeVisible}
					userRole={userRole}
					followersCount={followersCount}
					userFullName={userFullName}
					userName={userName}
					email={email}
					message={message}
					type={type}
					createdAt={createdAt}
					id={id}
					sub={selected?.sub}
				/>
			</Card>
		</>
	);
};
export default ReportsTable;
