import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
	Avatar,
	Select,
} from 'antd';
import {
	DeleteOutlined,
	EyeOutlined,
	EditOutlined,
	SketchOutlined,
} from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { UserDraver } from '../../../molecules/Draver';
import { Pagination } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../utils/device';
import { Option } from 'antd/es/mentions';

const FilterEpisode = styled.div`
	width: 100%;
	padding: 15px;
`;

const FilterFormLine = styled.div`
	width: 100%;
	display: flex;
`;

const FilterSearchButtonEpisode = styled.div`
	margin-left: auto;
	margin-top: 30px;
`;

const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;
const GiftModalUserAvatarContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
	margin-bottom: 10px;
`;
const UserNameWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: gray;
`;
const GiftModalUserName = styled.div`
	color: gray;
`;
const GiftModalFullName = styled.div`
	color: #111;
`;
const GiftSelectBoxWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
const GiftSubmitButton = styled.div`
	display: flex;
	margin-top: 30px;
`;
/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

const UserTable = () => {
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const [role, setRole] = useState();
	const [fullName, setFullName] = useState();
	const [username, setUsername] = useState();
	const [email, setEmail] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [gender, setGender] = useState();
	const [id, setId] = useState();
	const [total, setTotal] = useState();
	const [filterLoading, setFilterLoading] = useState(false);

	//gift
	const [isGiftModalOpen, setIsGiftModalOpen] = useState(false);
	const [getSelectedUser, setSelectedUser] = useState();
	const [getProductList, setProductList] = useState();
	const [getSelectedGift, setSelectedGift] = useState();

	const { confirm } = Modal;

	const showUserDetail = user => {
		setVisible(true);
		setRole(user.role);
		setFullName(user.fullname);
		setUsername(user.username);
		setEmail(user.email);
		setCreatedAt(user.createdAt);
		setGender(user.gender);
		setId(user.id);
	};

	const deleteUserProfile = userId => {
		confirm({
			title: `Bu kullanıcıyı silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/users/' + userId)
					.then(res => {
						window.location = '/users';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const editUserProfile = userId => {
		window.location = '/user/edit/' + userId;
	};

	const columns = [
		{
			title: `İsim`,
			dataIndex: 'fullname',
		},
		{
			title: `Kullanıcı Adı`,
			dataIndex: 'username',
		},
		{
			title: `Email`,
			dataIndex: 'email',
		},
		{
			title: `Rol`,
			dataIndex: 'role',
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: 'Vitrin Kullanıcısı',
			dataIndex: 'hasPublishedAtShowcase',
			render: value => {
				return <>{value ? 'Evet' : 'Hayır'}</>;
			},
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Hediye Ver'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5 gift-button"
							icon={<SketchOutlined style={{ fontSize: 20 }} />}
							onClick={() => {
								setIsGiftModalOpen(true);
								setSelectedUser(elm);
								getProductListFromServer();
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Düzenle'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5"
							icon={<EditOutlined />}
							onClick={() => {
								editUserProfile(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Görüntüle'}>
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								showUserDetail(elm);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteUserProfile(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const fetchUsers = () => {
		instanceApi()
			.get('/users?role=user')
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	const closeVisible = () => {
		setVisible(false);
	};

	const paginationOnChange = event => {
		instanceApi()
			.get('/users?role=user&page=' + event)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log(err));
	};

	const filterFormOnFinish = values => {
		instanceApi()
			.get('/search/users?query=' + values.search)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const filterFormOnFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const getProductListFromServer = () => {
		instanceApi()
			.get('/products')
			.then(res => {
				setProductList(res.data.results);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const handleOnGiftSelected = product => {
		setSelectedGift(product);
	};

	const hadnleSubmittingGift = () => {
		confirm({
			title: `${getSelectedUser.fullname} adli kullaniciya hediye olarak ${getSelectedGift} vermek istediginize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				handleOnPresSubmitGiftButton();
			},
			onCancel() {
				setIsGiftModalOpen(false);
				setSelectedGift();
			},
		});
	};
	const handleOnPresSubmitGiftButton = () => {
		const data = {
			userId: getSelectedUser.id,
			productId: getSelectedGift,
		};
		instanceApi()
			.post('/purchases/give-gift', data)
			.then(res => {
				if (res.status == 200) {
					setIsGiftModalOpen(false);
					setSelectedGift();
					alert('Basarili, hediye onaylandi.');
				}
			})
			.catch(err => {
				setSelectedGift();
				alert('hata');
				setIsGiftModalOpen(false);
				console.log('err: ', err);
			});
	};

	useEffect(() => {
		console.log(getSelectedUser, getSelectedGift);
	}, [getSelectedGift, getSelectedUser]);
	return (
		<>
			<FilterEpisode>
				<Card>
					<Form
						layout="vertical"
						name="filter-form"
						onFinish={filterFormOnFinish}
						onFinishFailed={filterFormOnFinishFailed}>
						<FilterFormLine>
							<Form.Item
								name="search"
								label="Kullanıcı İsmine Göre Ara"
								style={{ width: '300px' }}
								rules={[
									{
										required: false,
										message: 'Lütfen Arama alanını girin',
									},
								]}>
								<Input />
							</Form.Item>
							<FilterSearchButtonEpisode>
								<Form.Item>
									<ButtonSections>
										<PrimaryButton>
											<Button
												style={{ width: '180px' }}
												type="default"
												htmlType="submit"
												loading={filterLoading}>
												Ara
											</Button>
										</PrimaryButton>
									</ButtonSections>
								</Form.Item>
							</FilterSearchButtonEpisode>
						</FilterFormLine>
					</Form>
				</Card>
			</FilterEpisode>

			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
				<UserDraver
					visible={visible}
					close={closeVisible}
					role={role}
					fullname={fullName}
					username={username}
					email={email}
					createdAt={createdAt}
					gender={gender}
					id={id}
				/>
			</Card>
			<Modal
				visible={isGiftModalOpen}
				footer={null}
				onCancel={() => setIsGiftModalOpen(false)}>
				<GiftModalUserAvatarContainer>
					<Avatar src={getSelectedUser?.thumbnail} />
					<UserNameWrapper>
						<GiftModalFullName>
							{getSelectedUser?.fullname}
						</GiftModalFullName>
						<GiftModalUserName>
							{`@${getSelectedUser?.username}`}
						</GiftModalUserName>
					</UserNameWrapper>
				</GiftModalUserAvatarContainer>

				<GiftSelectBoxWrapper>
					<Select
						style={{ width: '100%' }}
						value={getSelectedGift || ' -- 💐 Hediye sec -- '}
						onChange={handleOnGiftSelected}>
						{getProductList?.map((product, i) => {
							return (
								<Select.Option value={product.id} key={i}>
									{product.name.tr}
								</Select.Option>
							);
						})}
					</Select>
				</GiftSelectBoxWrapper>

				{getSelectedGift && (
					<GiftSubmitButton>
						<div
							style={{ width: '100%' }}
							type="default"
							className="gift-button"
							htmlType="submit"
							onClick={hadnleSubmittingGift}>
							Onayla
						</div>
					</GiftSubmitButton>
				)}
			</Modal>
		</>
	);
};
export default UserTable;
