import React, { useEffect, useState } from "react";
import {Button, Card, Table, Tooltip, Modal, notification, Pagination} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from "../../../../services/Base/base.instance.services";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SliderTable = () => {
    const [ data, setData ]             = useState();
    const [ total, setTotal ]           = useState();

    const { confirm } = Modal;

    const deleteSlider = (sliderId) => {
        confirm({
            title: `Bu slider'ı silmek istediğinize emin misiniz?`,
            okText: `Evet`,
            okType: 'danger',
            cancelText: `Hayır`,
            onOk() {
                instanceApi()
                    .delete('/slides/' + sliderId)
                    .then(res => {
                        window.location = '/sliders';
                    })
                    .catch(err => {
                        console.log("err: ", err);
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const editSlider = (sliderId) => {
        window.location = '/slider/edit/' + sliderId;
    }

    const columns = [
        {
            title: `Id`,
            dataIndex: 'id',
        },
        {
            title: "Tip",
            dataIndex: 'type'
        },
        {
            title: "Başlangıç Tarihi",
            dataIndex: 'startDate'
        },
        {
            title: "Bitiş Tarihi",
            dataIndex: 'endDate'
        },
        {
            title: "Başlık Tipi",
            dataIndex: 'subjectType'
        },
        {
            title: `Resim`,
            dataIndex: 'image',
            render: image => <img src={image} style={{ width: "70px"}}/>
        },
        {
            title: `Link`,
            dataIndex: 'link',
            render: link => link ? link : 'Belirtilmemiş'
        },
        {
            title: "Tarih",
            dataIndex: 'createdAt'
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (_, elm) => (
                <div className="text-right d-flex justify-content-end">
                    <Tooltip title={'Düzenle'}>
                        <Button
                            style={{ marginRight: 10}}
                            className="mr-5"
                            icon={<EditOutlined />}
                            onClick={() => {
                                editSlider(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title={'Sil'}>
                        <Button
                            style={{ marginLeft: '12px' }}
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                deleteSlider(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const fetchSliders = () => {
        instanceApi()
            .get('/slides')
            .then(res => {
                setData(res.data.results);
                setTotal(res.data.totalResults);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        fetchSliders();
    },[]);

    const paginationOnChange = (event) => {
        instanceApi()
            .get('/slides?page=' + event)
            .then(res => {
                setData(res.data.results);
            })
            .catch(err => console.log(err));
    }

    return(
        <>
            <Card bodyStyle={{ padding: '0px'}}>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={data} pagination={false}/>
                    <Pagination
                        style={{ padding: "15px"}}
                        defaultCurrent={1}
                        defaultPageSize={10}
                        total={total}
                        onChange={paginationOnChange}
                    />
                </div>
            </Card>
        </>
    )
}
export default SliderTable