import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import { instanceApi } from '../../../services/Base/base.instance.services';
import { device } from '../../../utils/device';
import { createPolicy } from '../../../services/Policy';
import TextArea from 'antd/es/input/TextArea';
import RichTextEditor from 'components/common/RichTextEditor';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const CreatePrivacyPolicyForm = () => {
	const [loading, setLoading] = useState(false);
	const [languageValue, setLanguageValue] = useState();
	const [typeValue, setTypeValue] = useState();

	const onFinish = values => {
		setLoading(true);

		const policy = {
			title: values.title,
			content: values.content,
			language: languageValue,
			type: typeValue,
		};

		createPolicy(policy)
			.then(res => {
				setLoading(false);
				window.location = '/policies';
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
				notification.error({
					message: err.message,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const languageValueOnChange = event => {
		setLanguageValue(event);
	};

	const typeValueOnChange = event => {
		setTypeValue(event);
	};

	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="title"
						label="Başlık"
						rules={[
							{
								required: true,
								message: 'Lütfen Başlık alanını girin.',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="content"
						label="İçerik"
						rules={[
							{
								required: true,
								message: 'Lütfen içerik alanını girin.',
							},
						]}>
						{/* <TextArea rows={'20'} /> */}
						<RichTextEditor />
					</Form.Item>
					<Form.Item
						name="language"
						label="Dil"
						rules={[
							{
								required: true,
								message: 'Lütfen dil alanını girin.',
							},
						]}>
						<Select
							value={languageValue}
							onChange={languageValueOnChange}>
							<Option value={'tr'}>tr</Option>
							<Option value={'en'}>en</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="type"
						label="Tip"
						rules={[
							{
								required: true,
								message: 'Lütfen tip alanını girin.',
							},
						]}>
						<Select value={typeValue} onChange={typeValueOnChange}>
							<Option value={'privacy'}>
								Gizlilik Koşulları
							</Option>
							<Option value={'terms'}>Kullanım Koşulları</Option>
							<Option value={'consent'}>İzin & Rıza Metni</Option>
						</Select>
					</Form.Item>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/policies'}>
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};

export default CreatePrivacyPolicyForm;
