import React, { useEffect, useState} from "react";
import {Button, Card, Table, Tooltip, Modal, notification, Pagination} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from "../../../../services/Base/base.instance.services";

const AvatarTable = () => {
    const [ data, setData ]     = useState();
    const [ total, setTotal ]   = useState();

    const { confirm } = Modal;

    const deleteAvatar = (avatarId) => {
        confirm({
            title: `Bu avatarı silmek istediğinize emin misiniz?`,
            okText: `Evet`,
            okType: 'danger',
            cancelText: `Hayır`,
            onOk() {
                instanceApi()
                    .delete('/avatars/' + avatarId)
                    .then(res => {
                        window.location = '/avatars';
                    })
                    .catch(err => {
                        console.log("err: ", err);
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const editAvatar = (avatarId) => {
        window.location = '/avatar/edit/' + avatarId;
    }

    const columns = [
        {
            title: `Id`,
            dataIndex: 'id',
        },
        {
            title: `Resim`,
            dataIndex: 'image',
            render: image => <img src={image} style={{ width: "70px"}}/>
        },
        {
            title: `Aktif`,
            dataIndex: 'isActive',
            render: value => <p style={{ color: "black"}}>{value === true ? 'Aktif' : 'Pasif'}</p>
        },
        {
            title: "Tarih",
            dataIndex: 'createdAt'
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (_, elm) => (
                <div className="text-right d-flex justify-content-end">
                    <Tooltip title={'Düzenle'}>
                        <Button
                            style={{ marginRight: 10}}
                            className="mr-5"
                            icon={<EditOutlined />}
                            onClick={() => {
                                editAvatar(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title={'Sil'}>
                        <Button
                            style={{ marginLeft: '12px' }}
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                deleteAvatar(elm.id);
                            }}
                            size="small"
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const fetchAvatars = () => {
        instanceApi()
            .get('/avatars')
            .then(res => {
                setData(res.data.results);
                setTotal(res.data.totalResults);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        fetchAvatars();
    },[]);

    const paginationOnChange = (event) => {
        instanceApi()
            .get('/avatars?page=' + event)
            .then(res => {
                setData(res.data.results);
            })
            .catch(err => console.log(err));
    }

    return(
        <>
            <Card bodyStyle={{ padding: '0px'}}>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={data} pagination={false}/>
                    <Pagination
                        style={{ padding: "15px"}}
                        defaultCurrent={1}
                        defaultPageSize={10}
                        total={total}
                        onChange={paginationOnChange}
                    />
                </div>
            </Card>
        </>
    )
}
export default AvatarTable