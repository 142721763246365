import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Table,
    Tooltip,
    Modal,
    notification,
    Form,
    Input,
    Select,
    InputNumber,
    Pagination,
    Checkbox
} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from "../../../../services/Base/base.instance.services";
import { WorkDraver } from "../../../molecules/Draver";
import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";
import {device} from "../../../../utils/device";
import {createHomeList} from "../../../../services/HomeList";

const ButtonEpisode = styled.div`
    width: 30%;
    padding: 15px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PopularWorkTable = () => {
    const [ data, setData ]                             = useState([]);
    const [ visible, setVisible ]                       = useState(false);
    const [ title, setTitle ]                           = useState();
    const [ introduction, setIntroduction ]             = useState();
    const [ slug, setSlug ]                             = useState();
    const [ publicationType, setPublicationType ]       = useState();
    const [ isActive, setIsActive ]                     = useState();
    const [ likesCount, setLikesCount ]                 = useState();
    const [ totalRating, setTotalRating ]               = useState();
    const [ totalRatingCount, setTotalRatingCount ]     = useState();
    const [ workType, setWorkType ]                     = useState();
    const [ categoryTitle, setCategoryTitle ]           = useState();
    const [ publishDate, setPublishDate ]               = useState();
    const [ creatorFullName, setCreatorFullName ]       = useState();
    const [ creatorUserName, setCreatorUserName ]       = useState();
    const [ id, setId ]                                 = useState();
    const [ createdAt, setCreatedAt]                    = useState();

    const showWorkDetail = (work) => {
        setVisible(true);
        setTitle(work.title.tr);
        setIntroduction(work.introduction.tr);
        setSlug(work.slug.tr);
        setPublicationType(work.publicationType);
        setIsActive(work.isActive);
        setLikesCount(work.likesCount);
        setTotalRating(work.totalRating);
        setTotalRatingCount(work.totalRatingCount);
        setCategoryTitle(work.category.title.tr);
        setPublishDate(work.publishDate);
        setWorkType(work.workType);
        setCreatorFullName(work.creator.fullname);
        setCreatorUserName(work.creator.username);
        setCreatedAt(work.createdAt);
        setId(work.id);
    }

    const columns = [
        {
            title: `Başlık`,
            dataIndex: ['title','tr'],
        },
        {
            title: `Kategori`,
            dataIndex: ['category','title',['tr']],
        },
        {
            title: `Yayın Türü`,
            dataIndex: 'publicationType',
        },
        {
            title: "Kitay Tipi",
            dataIndex: 'workType'
        },
        {
            title: "Beğeni Sayısı",
            dataIndex: 'likesCount'
        },
        {
            title: "Değerlendirme Sayısı",
            dataIndex: 'totalRatingCount'
        },
        {
            title: "Tarih",
            dataIndex: 'createdAt'
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (_, elm) => (
                <div className="text-right d-flex justify-content-end">
                    <Tooltip title={'Görüntüle'}>
                        <Button
                            type="primary"
                            className="mr-2"
                            icon={<EyeOutlined />}
                            onClick={() => {
                                showWorkDetail(elm);
                            }}
                            size="small"
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const fetchBooksTotal = () => {
        instanceApi()
            .get('/works?sortBy=likesCount:desc&limit=10')
            .then(res => {
                setData(res.data.results)
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        fetchBooksTotal();
    },[]);

    const closeVisible = () => {
        setVisible(false);
    }

    return(
        <>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
                <WorkDraver
                    visible={visible}
                    close={closeVisible}
                    title={title}
                    introduction={introduction}
                    slug={slug}
                    publicationType={publicationType}
                    isActive={isActive}
                    likesCount={likesCount}
                    totalRating={totalRating}
                    totalRatingCount={totalRatingCount}
                    workType={workType}
                    categoryTitle={categoryTitle}
                    publishDate={publishDate}
                    creatorFullName={creatorFullName}
                    creatorUserName={creatorUserName}
                    createdAt={createdAt}
                    id={id}
                />
            </Card>
        </>
    )
}
export default PopularWorkTable