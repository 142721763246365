import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 10,
	text-aling:"center"
	padding: 20px;
	font-size: 16px;
	margin: 30px 0;
`;
const LoadingText = styled.div`
	display: flex;
	justify-content: center;
	padding: 20px;
	width: 100%,
	font-size: 16px;
	
`;
const Loading = props => {
	const { align, cover, text } = props;

	if (text) {
		return (
			<LoadingWrapper>
				<div className={`loading text-${align} cover-${cover}`}>
					<Spin indicator={Icon} />
				</div>
				<LoadingText>
					<div>{text}</div>
				</LoadingText>
			</LoadingWrapper>
		);
	}

	return (
		<div className={`loading text-${align} cover-${cover}`}>
			<Spin indicator={Icon} />
		</div>
	);
};

Loading.propTypes = {
	size: PropTypes.string,
	cover: PropTypes.string,
};

Loading.defaultProps = {
	align: 'center',
	cover: 'inline',
};

export default Loading;
