import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from '../../molecules/TopMenu/Logo';
import NavProfile from '../../molecules/TopMenu/NavProfile';
import NavLanguage from '../../molecules/TopMenu/NavLanguage';
import NavPanel from "../../molecules/TopMenu/NavPanel";
import SearchInput from "../../molecules/TopMenu/NavSearch/SearchInput";
import styled from "styled-components";
import {device} from "../../../utils/device";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TopMenu = ({ setIsToogleActive }) => {
    const { Header } = Layout;

    const [hamburgerMenuStatus, setHamburgerMenuStatus] = useState(0);

    const [ toogleStatus, setToogleStatus] = useState(false);

    const getNavWidth = () => {
        return '0px';
    }

    const onToggle = () => {
        setHamburgerMenuStatus(!hamburgerMenuStatus);
        setToogleStatus(!toogleStatus);
        setIsToogleActive(!toogleStatus);
    }

    return (
        <>
            <Header
                className={`app-header`}
                style={{ backgroundColor: "white"}}>
                <div
                    className={`app-header-wrapper`}>
                    <Logo status={hamburgerMenuStatus}/>
                    <div
                        className="nav"
                        style={{ width: `calc(100% - ${getNavWidth()})` }}>
                        <div className="nav-left">
                            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => onToggle()}>
                                    <MenuFoldOutlined className="nav-icon" />
                                </li>
                            </ul>
                        </div>
                        <div className="nav-right">
                            <NavProfile />
                        </div>
                    </div>
                </div>
            </Header>
        </>
    );
};

export default TopMenu;