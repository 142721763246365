import { useHistory } from "react-router-dom"
import { Button } from 'antd';

const Route404 = () => {
    const history = useHistory();
    return (
        <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontSize: "60px" }}>Route Not Found</h1>
            <Button style={{cursor:"pointer"}} onClick={() => history.push("/dashboard")}>Please Click </Button >
        </div>

    )
}

export default Route404