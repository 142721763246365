import React, {useState} from "react";
import SideMenu from "../../components/organisms/SideMenu";
import TopMenu from "../../components/organisms/TopMenu";
import styled from "styled-components";
import { EditAvatar } from "../../components/organisms/Avatar";
import { device } from "../../utils/device";
import HorizontalMenu from "../../components/organisms/HorizontalMenu";
import {Helmet} from "react-helmet";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
	display: flex;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const SideMenuSection = styled.div`
	position: fixed;
	
	@media ${device.ipadL} {
		display: none;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const MobileMenu = styled.div`
	display: none;
	width: 100%;
	
	@media ${device.ipadL} {
		display: block;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const EditAvatarPage = props => {

    const id = props.match.params.id;

    const [isToogleActive, setIsToogleActive] = useState(false);

    if(isToogleActive) {
        var marginLeft = 90;
    } else {
        var marginLeft = 270;
    }

    const Content = styled.div`
	width: 100%;
	margin-top: 100px;
	margin-left: ${marginLeft}px;
	margin-right: 30px;
	    
	    @media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

    return(
        <>
            <Helmet>
                <title>Çizgi App - Admin Panel</title>
            </Helmet>
            <TopSection>
                <TopMenu setIsToogleActive={setIsToogleActive} />
            </TopSection>
            <MobileMenu>
                <HorizontalMenu isToogleActive={isToogleActive}/>
            </MobileMenu>
            <Container>
                <SideMenuSection>
                    <SideMenu sideNavTheme={'SIDE_NAV_LIGHT'} isToogleActive={isToogleActive}/>
                </SideMenuSection>
                <Content>
                    <EditAvatar id={id}/>
                </Content>
            </Container>
        </>
    )
}
export default EditAvatarPage