import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
	Select,
	InputNumber,
	Pagination,
} from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const HomeListTable = () => {
	const [data, setData] = useState([]);
	const [total, setTotal] = useState();

	const { confirm } = Modal;

	const showHomeListDetail = homeList => {
		window.location = '/home-lists/' + homeList.id;
	};

	const deleteHomeList = homeListId => {
		confirm({
			title: `Bu listeyi silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/home-lists/' + homeListId)
					.then(res => {
						window.location = '/home-lists';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const columns = [
		{
			title: `Sıra`,
			dataIndex: 'order',
		},
		{
			title: `Başlık`,
			dataIndex: ['title'],
		},

		{
			title: `Durum`,
			dataIndex: 'isActive',
			render: isActive => (isActive === true ? 'Aktif' : 'Pasif'),
		},
		{
			title: 'Dil',
			dataIndex: 'language',
			render: lang => {
				const lookup = { tr: 'Turkce', en: 'English' };

				return <>{lookup[lang]}</>;
			},
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Görüntüle'}>
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								showHomeListDetail(elm);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteHomeList(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	useEffect(() => {
		fetchHomeLists();
	}, []);

	const paginationOnChange = event => {
		instanceApi()
			.get('/home-lists?page=' + event)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log(err));
	};

	const fetchHomeLists = () => {
		instanceApi()
			.get('/home-lists')
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
			</Card>
		</>
	);
};
export default HomeListTable;
