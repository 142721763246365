import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
} from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { Pagination } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../utils/device';
import { PostDrawer, UserDraver } from 'components/molecules/Draver';
import ImageViewer from 'components/common/ImageViewer';

const FilterEpisode = styled.div`
	width: 100%;
	padding: 15px;
`;

const FilterFormLine = styled.div`
	width: 100%;
	display: flex;
`;

const FilterSearchButtonEpisode = styled.div`
	margin-left: auto;
	margin-top: 30px;
`;

const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;
const PostImageWrapper = styled.div`
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 20px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

const SocialPostsTable = () => {
	const [data, setData] = useState();
	const [total, setTotal] = useState();
	const [filterLoading, setFilterLoading] = useState(false);

	const [visible, setVisible] = useState(false);
	const [role, setRole] = useState();
	const [fullName, setFullName] = useState();
	const [username, setUsername] = useState();
	const [email, setEmail] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [gender, setGender] = useState();
	const [id, setId] = useState();
	const [modalVisible, setModalVisible] = useState(false);
	const [getActivePostImage, setActivePostImage] = useState();
	const [getSearchKeyword, setSearchKeyword] = useState();
	const [getCurrentPage, setCurrentPage] = useState(1);
	const [selectedPost, setSelectedPost] = useState();

	const { confirm } = Modal;

	const columns = [
		{
			title: `İsim`,
			dataIndex: 'user',
			render: user => {
				return (
					<div>
						<span>{user?.fullname} </span>
						<a
							href={`user/edit/${user?.id}`}>{`@${user?.username}`}</a>
					</div>
				);
			},
		},
		{
			title: `Resim`,
			dataIndex: 'images',
			render: images => {
				const image = images[0];
				if (!image) return <></>;
				return (
					<PostImageWrapper>
						<img
							onClick={() => handleOnClickImage(image)}
							src={image}
							style={{
								width: '100%',
								height: '100%',
								cursor: 'pointer',
								objectFit: 'cover',
							}}
						/>
					</PostImageWrapper>
				);
			},
		},

		{
			title: `Gonderi`,
			dataIndex: 'content',
			render: content => {
				if (content) return <div>{renderPostContent(content)}</div>;
			},
		},

		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: 'Vitrin Kullanıcısı',
			dataIndex: 'hasPublishedAtShowcase',
			render: value => {
				return <>{value ? 'Evet' : 'Hayır'}</>;
			},
		},
		{
			title: '#',
			dataIndex: 'actions',
			render: (_, elm) => {
				return (
					<div className="text-right d-flex justify-content-end">
						<Tooltip title={'Görüntüle'}>
							<Button
								type="primary"
								className="mr-2"
								icon={<EyeOutlined />}
								onClick={() => {
									setSelectedPost(elm);
									showUserDetail(elm?.user);
								}}
								size="small"
							/>
						</Tooltip>
						<Tooltip title={'Sil'}>
							<Button
								style={{ marginLeft: '12px' }}
								danger
								icon={<DeleteOutlined />}
								onClick={() => {
									deleteSocialPost(elm.id);
								}}
								size="small"
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const deleteSocialPost = userId => {
		confirm({
			title: `Bu gonderiyi silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/posts/' + userId)
					.then(res => {
						fetchSocialPostFromServer();
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const renderPostContent = content => {
		const contentLength = content.length;
		const splitedContent = content.split(' ');
		content = splitedContent.slice(0, 20).join(' ');
		if (contentLength) {
			content = content.trim() + '...';
		}
		return content;
	};

	useEffect(() => {
		fetchSocialPostFromServer();
	}, [getCurrentPage, getSearchKeyword]);

	//search for post
	const fetchSocialPostFromServer = values => {
		let url =
			'/posts/search?sortBy=createdAt:desc&page=' +
			getCurrentPage +
			'&query=' +
			getSearchKeyword;

		if (!getSearchKeyword) {
			url =
				'/posts/feed/all?sortBy=createdAt:desc&page=' + getCurrentPage;
		}

		console.log(url);
		instanceApi()
			.get(url)
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const fetchSocialPostFromServerFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const closeVisible = () => {
		setVisible(false);
	};

	const showUserDetail = user => {
		setVisible(true);
		setRole(user.role);
		setFullName(user.fullname);
		setUsername(user.username);
		setEmail(user.email);
		setCreatedAt(user.createdAt);
		setGender(user.gender);
		setId(user.id);
	};

	const handleOnClickImage = image => {
		setModalVisible(true);
		setActivePostImage(image);
	};

	return (
		<>
			<FilterEpisode>
				<Card>
					<Form
						layout="vertical"
						name="filter-form"
						onFinish={text => {
							setSearchKeyword(text.search);
							setCurrentPage(1);
						}}
						onFinishFailed={fetchSocialPostFromServerFailed}>
						<FilterFormLine>
							<Form.Item
								name="search"
								label="Gonderi ara"
								style={{ width: '300px' }}
								rules={[
									{
										required: false,
										message: 'Bir seyler yaz',
									},
								]}>
								<Input />
							</Form.Item>
							<FilterSearchButtonEpisode>
								<Form.Item>
									<ButtonSections>
										<PrimaryButton>
											<Button
												style={{ width: '180px' }}
												type="default"
												htmlType="submit"
												loading={filterLoading}>
												Post Ara
											</Button>
										</PrimaryButton>
									</ButtonSections>
								</Form.Item>
							</FilterSearchButtonEpisode>
						</FilterFormLine>
					</Form>
				</Card>
			</FilterEpisode>

			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={getCurrentPage}
						defaultPageSize={20}
						total={total}
						onChange={event => setCurrentPage(event)}
					/>
				</div>
				<PostDrawer
					visible={visible}
					close={closeVisible}
					role={role}
					fullname={fullName}
					username={username}
					email={email}
					createdAt={createdAt}
					gender={gender}
					id={id}
					post={selectedPost}
				/>

				<ImageViewer
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
					link={getActivePostImage}
				/>
			</Card>
		</>
	);
};
export default SocialPostsTable;
