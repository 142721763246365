import React, { useEffect, useState } from 'react';
import StatisticCardWidget from '../../molecules/StatisticCardWidget';
import styled from 'styled-components';
import { Button } from 'antd';
import { device } from '../../../utils/device';
import PopularWorkTable from '../../molecules/Tables/PopularWork';
import theme from '../../../theme';
import { instanceApi } from '../../../services/Base/base.instance.services';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSection = styled.div`
	margin-left: auto;

	@media ${device.ipadL} {
		margin-right: 15px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const CardsSection = styled.div`
	display: flex;
	margin-top: 15px;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TableSection = styled.div`
	margin-top: 15px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const CardWidgetOne = styled.div`
	width: 25%;

	@media ${device.ipadL} {
		margin-left: 15px;
	}

	@media ${device.mobileL} {
		margin-left: 0px;
		width: 98%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const CardWidgetTwo = styled.div`
	width: 25%;
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		width: 98%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const CardWidgetThree = styled.div`
	width: 25%;
	margin-left: 10px;

	@media ${device.ipadL} {
		margin-right: 15px;
	}

	@media ${device.mobileL} {
		margin-left: 0px;
		width: 98%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSection = styled.div`
	display: block;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSectionText = styled.h2`
	@media ${device.ipadL} {
		font-size: 18px;
	}

	@media ${device.mobileL} {
		font-size: 15px;
	}

	@media ${device.mobileM} {
		font-size: 14px;
	}

	@media ${device.mobileS} {
		font-size: 13px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
	display: flex;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Title = styled.div``;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleText = styled.h2`
	font-size: 29px;
	color: ${theme.darkBlue};

	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescription = styled.div``;

/**
 *
 * @type {StyledComponent<"p", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescriptionText = styled.label`
	font-size: 15px;
	color: ${theme.lightBlue};
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;
/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Dashboard = () => {
	const [totalUsers, setTotalUsers] = useState(0);
	const [totalSystemUsers, setTotalSystemUsers] = useState(0);
	const [totalReadingList, setTotalReadingList] = useState(0);
	const [totalCategoryList, setTotalCategoryList] = useState(0);

	const fetchTotalUsers = () => {
		instanceApi()
			.get('/users?role=user')
			.then(res => {
				setTotalUsers(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	const fetchTotalSystemUsers = () => {
		instanceApi()
			.get('/users?limit=1000&role=admin')
			.then(res => {
				setTotalSystemUsers(res.data.totalResults);
			});
	};

	const fetchTotalCategories = () => {
		instanceApi()
			.get('/categories?limit=1000')
			.then(res => {
				setTotalCategoryList(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		fetchTotalUsers();
		fetchTotalSystemUsers();
		fetchTotalCategories();
	});

	return (
		<>
			<Container>
				<TopSection>
					<TitleSection>
						<Title>
							<TitleText>Gösterge</TitleText>
						</Title>
						<TitleDescription>
							<TitleDescriptionText>
								Bu sayfa üzerinden tüm aktiviteleri
								görebilirsiniz.
							</TitleDescriptionText>
						</TitleDescription>
					</TitleSection>
					<ButtonSection>
						<Button href="#" type="primary" htmlType="submit">
							Okuma Listesi Oluştur
						</Button>
					</ButtonSection>
				</TopSection>
				<CardsSection>
					<CardWidgetOne>
						<StatisticCardWidget
							title={'Kullanıcılar'}
							value={totalUsers}
							subtitle={'Toplam'}
						/>
					</CardWidgetOne>
					<CardWidgetTwo>
						<StatisticCardWidget
							title={'Okuma Listeleri'}
							value={totalReadingList}
							subtitle={'Toplam'}
						/>
					</CardWidgetTwo>
					<CardWidgetThree>
						<StatisticCardWidget
							title={'Sistem Kullanıcıları'}
							value={totalSystemUsers}
							subtitle={'Toplam'}
						/>
					</CardWidgetThree>
					<CardWidgetThree>
						<StatisticCardWidget
							title={'Kategoriler'}
							value={totalCategoryList}
							subtitle={'Toplam'}
						/>
					</CardWidgetThree>
				</CardsSection>
				<TitleSection>
					<Title>
						<TitleText>Popüler 10 Kitap</TitleText>
					</Title>
					<TitleDescription>
						<TitleDescriptionText>
							Bu kısımda popüler olan 10 kitabı
							görüntüleyebilirsiniz.
						</TitleDescriptionText>
					</TitleDescription>
				</TitleSection>
				<TableSection>
					<PopularWorkTable />
				</TableSection>
			</Container>
		</>
	);
};
export default Dashboard;
