import React from "react";
import styled from "styled-components";
import { device } from "../../../utils/device";
import ReportsTable from "../../molecules/Tables/Reports";
import theme from "../../../theme";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSection = styled.div`
	display: block;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Title = styled.div`
`;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleText = styled.h2`
	font-size: 29px;
	color: ${theme.darkBlue};
	
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescription = styled.div`
`;

/**
 *
 * @type {StyledComponent<"p", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescriptionText = styled.label`
	font-size: 15px;
	color: ${theme.lightBlue};
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TableSection = styled.div`
	margin-top: 20px;
	
	@media ${device.ipad} {
		margin-right: 15px;
	}
	
	@media ${device.mobileL} {
		margin-right: 15px;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Reports = () => {
    return(
        <>
            <Container>
                <TopSection>
                    <TitleSection>
                        <Title>
                            <TitleText>Raporlar</TitleText>
                        </Title>
                        <TitleDescription>
                            <TitleDescriptionText>Bu sayfa üzerinden tüm raporları listeleyebilirsiniz.</TitleDescriptionText>
                        </TitleDescription>
                    </TitleSection>
                </TopSection>
                <TableSection>
                    <ReportsTable/>
                </TableSection>
            </Container>
        </>
    )
}
export default Reports