import React from 'react';
import Login from '../../components/organisms/Auth/Login';
import { Helmet } from 'react-helmet';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LoginPage = () => {
	return (
		<>
			<Helmet>
				<title>Çizgi App - Admin Panel</title>
			</Helmet>
			<Login />
		</>
	);
};

export default LoginPage;
