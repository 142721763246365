import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import {instanceApi} from "../../../services/Base/base.instance.services";
import { device } from "../../../utils/device";
import {updatePolicy, updateTerm} from "../../../services/Policy";
import TextArea from "antd/es/input/TextArea";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;
	
	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;
	
	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const EditTermOfUseDetailForm = ({ id }) => {

    const [ loading, setLoading ]               = useState(false);
    const [ title, setTitle ]                   = useState();
    const [ content, setContent ]               = useState();

    useEffect(() => {
        fetchTermOfUseDetail(id);
    },[]);

    const fetchTermOfUseDetail = (id) => {
        instanceApi()
            .get('/policies/' + id)
            .then(res => {
                setTitle(res.data.title);
                setContent(res.data.content);
            })
            .catch(err => console.log(err));
    }

    const onFinish = values => {
        setLoading(true);

        const term = {
            title: values.title,
            content: values.content
        };

        updateTerm(term,id)
            .then(res => {
                setLoading(false);
                window.location = '/term-of-use/edit/' + id;
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                notification.error({
                    message: err.message
                })
            });

    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
        notification.error({
            message: `Bilgileri kontrol edip tekrar deneyiniz!`,
        });
    };

    return(
        <>
            <FormContainer>
                <Form
                    layout="vertical"
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <Form.Item
                        name="title"
                        label="Başlık"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen Başlık alanını girin.',
                            },
                        ]}>
                        <Input placeholder={title}/>
                    </Form.Item>

                    <Form.Item
                        name="content"
                        label="İçerik"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen içerik alanını girin.',
                            }
                        ]}>
                        <TextArea rows={'20'} placeholder={content}/>
                    </Form.Item>

                    <Form.Item>
                        <ButtonSections>
                            <PrimaryButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}>
                                    Güncelle
                                </Button>
                            </PrimaryButton>
                            <DefaultButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="default"
                                    href={'/terms-of-use'}>
                                    Geri
                                </Button>
                            </DefaultButton>
                        </ButtonSections>
                    </Form.Item>

                </Form>
            </FormContainer>
        </>
    )
}

export default EditTermOfUseDetailForm