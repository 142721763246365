import React, {useEffect, useState} from "react";
import {instanceApi} from "../../../services/Base/base.instance.services";
import styled from "styled-components";
import theme from "../../../theme";
import {Card} from "antd";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Line = styled.div`
    display: flex;
    margin-top: 20px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const LeftSection = styled.div`
    width: 50%;
    display: flex;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const RightSection = styled.div`
    width: 50%;
    display: flex;
    margin-left: 10px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Text = styled.div`
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Value = styled.div`
    margin-left: 10px;
`;

/**
 *
 * @type {StyledComponent<"label", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const BoldText = styled.label`
    font-size: 16px;
    font-weight: bold;
    color: ${theme.darkBlue};
`;

/**
 *
 * @type {StyledComponent<"label", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const NormalText = styled.label`
    font-size: 16px;
    color: ${theme.darkBlue};
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileInfoCard = () => {
    const [ fullName, setFullName ]         = useState();
    const [ username, setUsername ]         = useState();
    const [ email, setEmail ]               = useState();
    const [ createdAt, setCreatedAt ]       = useState();
    const [ role, setRole ]                 = useState();

    useEffect(() => {
        fetchUserDetail();
    },[]);

    const fetchUserDetail = () => {
        instanceApi()
            .get('/users/' + localStorage.getItem('userId'))
            .then(res => {
                setFullName(res.data.fullname);
                setUsername(res.data.username);
                setEmail(res.data.email);
                setCreatedAt(res.data.createdAt);
                setRole(res.data.role);
            })
            .catch(err => console.log(err));
    }

    return(
        <>
            <Card>
                <Line>
                    <LeftSection>
                        <Text>
                            <BoldText>İsim Soyisim: </BoldText>
                        </Text>
                        <Value>
                            <NormalText>{fullName}</NormalText>
                        </Value>
                    </LeftSection>
                    <RightSection>
                        <Text>
                            <BoldText>Kullanıcı Adı: </BoldText>
                        </Text>
                        <Value>
                            <NormalText>{username}</NormalText>
                        </Value>
                    </RightSection>
                </Line>
                <Line>
                    <LeftSection>
                        <Text>
                            <BoldText>Email: </BoldText>
                        </Text>
                        <Value>
                            <NormalText>{email}</NormalText>
                        </Value>
                    </LeftSection>
                    <RightSection>
                        <Text>
                            <BoldText>Rol: </BoldText>
                        </Text>
                        <Value>
                            <NormalText>{role}</NormalText>
                        </Value>
                    </RightSection>
                </Line>
                <Line>
                    <LeftSection>
                        <Text>
                            <BoldText>Tarih: </BoldText>
                        </Text>
                        <Value>
                            <NormalText>{createdAt}</NormalText>
                        </Value>
                    </LeftSection>
                </Line>
            </Card>
        </>
    )
}
export default ProfileInfoCard