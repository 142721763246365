import React, { useEffect, useState } from 'react';
import { Button, Upload, message } from 'antd';
import { instanceApi } from 'services/Base/base.instance.services';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Layout from 'components/common/Layout';

const EpisodeDetailPage = ({ match }) => {
	const { workId, episodeId } = match.params;
	const [episode, setEpisode] = useState(null);
	const history = useHistory();
	const [audio, setAudio] = useState(null);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		fetchData();
	}, [episodeId]);

	const fetchData = async () => {
		if (!episodeId) return;
		const { data } = await instanceApi().get(
			`/works/${workId}/episodes/${episodeId}`
		);

		if (data) {
			setEpisode(data);
		}
	};

	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('audio', audio);
		setUploading(true);
		// You can use any AJAX library you like
		try {
			const { data } = await instanceApi().put(
				`/works/${workId}/episodes/${episodeId}/audio`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (data.url) {
				setEpisode(e => ({ ...e, audioUrl: data.url }));
				setAudio(null);
				message.success('Başarıyla yüklendi');
			}
		} catch (error) {
			message.error('Dosya yüklenirken bir hata oluştu.');
		} finally {
			setUploading(false);
		}
	};

	const uploadProps = {
		onRemove: file => {
			setAudio(null);
		},
		beforeUpload: file => {
			setAudio(file);

			return false;
		},
		file: audio,
	};

	return (
		<Layout>
			<div>
				<div>
					<h6 className="text-muted text-uppercase mb-3">
						Genel Bilgiler
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Id:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episodeId}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Başlık:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.title?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Slug:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.slug?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yayın Durumu:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.isActive === true ? 'Aktif' : 'Pasif'}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Beğeni Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.likesCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kilitli mi?:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.isLocked ? 'Evet' : 'Hayır'}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Tarih:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.createdAt}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Sesli Kitap Dosyası:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{episode?.audioUrl ? (
								<a href={episode.audioUrl} target="__blank">
									{episode.audioUrl}
								</a>
							) : (
								'Yok'
							)}
							<div>
								<Upload {...uploadProps}>
									<Button icon={<UploadOutlined />}>
										Dosya Seç
									</Button>
								</Upload>
								{!!audio && (
									<Button
										type="primary"
										onClick={handleUpload}
										loading={uploading}
										style={{ marginTop: 16 }}>
										{uploading
											? 'Yükleniyor...'
											: 'Yüklemeye Başla'}
									</Button>
								)}
							</div>
						</span>
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default EpisodeDetailPage;
