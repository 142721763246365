import {
	ApartmentOutlined,
	BankOutlined,
	BookOutlined,
	CommentOutlined,
	ContactsOutlined,
	CrownOutlined,
	DashboardOutlined,
	DotChartOutlined,
	EuroCircleOutlined,
	SettingOutlined,
	StarOutlined,
	UserOutlined,
	UserSwitchOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../util-components/Icon';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const WebSideMenu = styled.div`
	width: 250px;
	padding-top: 70px;
	height: 100vh;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const MobileSideMenu = styled.div`
	width: 70px;
	padding-top: 70px;
	height: 100vh;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const MenuTitleSection = styled.div`
	margin-top: 20px;
	margin-bottom: 10px;
	margin-left: 25px;
`;

/**
 *
 * @type {StyledComponent<"h1", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const MenuTitle = styled.h1`
	font-size: 14px;
	font-weigh: 100;
	color: #455560;
`;

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SideMenu = ({ isToogleActive }) => {
	return (
		<>
			{isToogleActive ? (
				<div>
					<MobileSideMenu>
						<Menu
							theme={'light'}
							mode="inline"
							style={{ height: '100%', borderRight: 0 }}
							className="hide-group-title">
							<Menu.Item key="dashboard">
								<Icon type={DashboardOutlined} />
								<Link to="/dashboard" />
							</Menu.Item>

							<Menu.Item key="users">
								<Icon type={UserOutlined} />
								<Link to="/users" />
							</Menu.Item>

							<Menu.Item key="social-posts">
								<Icon type={UserSwitchOutlined} />
								<Link to="/social-posts" />
							</Menu.Item>

							<Menu.Item key="showcase-requests">
								<Icon type={ContactsOutlined} />
								<Link to="/showcase-requests" />
							</Menu.Item>

							<Menu.Item key="moderation">
								<Icon type={BankOutlined} />
								<Link to="/moderation" />
							</Menu.Item>

							<Menu.Item key="monetizations">
								<Icon type={EuroCircleOutlined} />
								<Link to="/monetizations" />
							</Menu.Item>

							<Menu.Item key="system-users">
								<Icon type={UserOutlined} />
								<Link to="/system-users" />
							</Menu.Item>

							<Menu.Item key="works">
								<Icon type={BookOutlined} />
								<Link to="/works" />
							</Menu.Item>

							<Menu.Item key="home-lists">
								<Icon type={DotChartOutlined} />
								<Link to="/home-lists" />
							</Menu.Item>

							<Menu.Item key="categories">
								<Icon type={CrownOutlined} />
								<Link to="/categories" />
							</Menu.Item>

							<Menu.Item key="avatars">
								<Icon type={StarOutlined} />
								<Link to="/avatars" />
							</Menu.Item>

							<Menu.Item key="sliders">
								<Icon type={ApartmentOutlined} />
								<Link to="/sliders" />
							</Menu.Item>

							<Menu.Item key="active-sliders">
								<Icon type={ApartmentOutlined} />
								<Link to="/active-sliders" />
							</Menu.Item>

							<Menu.Item key="policies">
								<Icon type={BankOutlined} />
								<Link to="/policies" />
							</Menu.Item>

							<Menu.Item key="reports">
								<Icon type={BankOutlined} />
								<Link to="/reports" />
							</Menu.Item>

							<Menu.Item key="settings">
								<Icon type={SettingOutlined} />
								<span>{'Ayarlar'}</span>
								<Link to="/settings" />
							</Menu.Item>
						</Menu>
					</MobileSideMenu>
				</div>
			) : (
				<div>
					<WebSideMenu>
						<Menu
							theme={'light'}
							mode="inline"
							style={{ height: '100%', borderRight: 0 }}
							className="hide-group-title">
							<MenuTitleSection>
								<MenuTitle>Admin Panel</MenuTitle>
							</MenuTitleSection>
							<Menu.Item key="dashboard">
								<Icon type={DashboardOutlined} />
								<span>{'Gösterge'}</span>
								<Link to="/dashboard" />
							</Menu.Item>

							<Menu.Item key="users">
								<Icon type={UserOutlined} />
								<span>{'Kullanıcılar'}</span>
								<Link to="/users" />
							</Menu.Item>
							<Menu.Item key="social-posts">
								<Icon type={UserSwitchOutlined} />
								<span>{'Gönderiler'}</span>
								<Link to="/social-posts" />
							</Menu.Item>

							<Menu.Item key="showcase-requests">
								<Icon type={ContactsOutlined} />
								<span>{'Vitrin Talepleri'}</span>
								<Link to="/showcase-requests" />
							</Menu.Item>

							<Menu.Item key="chatbot">
								<Icon type={CommentOutlined} />
								<span>{'Chat Bot'}</span>
								<Link to="/chatbot" />
							</Menu.Item>

							<Menu.Item key="moderation">
								<Icon type={BankOutlined} />
								<span>{'Moderasyon'}</span>
								<Link to="/moderation" />
							</Menu.Item>
							<Menu.Item key="monetizations">
								<Icon type={EuroCircleOutlined} />
								<span>{'Ödeme Talepleri'}</span>
								<Link to="/monetizations" />
							</Menu.Item>

							<Menu.Item key="system-users">
								<Icon type={UserOutlined} />
								<span>{'Sistem Kullanıcıları'}</span>
								<Link to="/system-users" />
							</Menu.Item>

							<Menu.Item key="works">
								<Icon type={BookOutlined} />
								<span>{'Kitaplar'}</span>
								<Link to="/works" />
							</Menu.Item>

							<Menu.Item key="home-lists">
								<Icon type={DotChartOutlined} />
								<span>{'Anasayfa Listeleri'}</span>
								<Link to="/home-lists" />
							</Menu.Item>

							<Menu.Item key="categories">
								<Icon type={CrownOutlined} />
								<span>{'Kategoriler'}</span>
								<Link to="/categories" />
							</Menu.Item>

							<Menu.Item key="avatarts">
								<Icon type={StarOutlined} />
								<span>{'Avatarlar'}</span>
								<Link to="/avatars" />
							</Menu.Item>

							<Menu.Item key="sliders">
								<Icon type={ApartmentOutlined} />
								<span>{'Slider'}</span>
								<Link to="/sliders" />
							</Menu.Item>

							<Menu.Item key="active-sliders">
								<Icon type={ApartmentOutlined} />
								<span>{'Aktif Slider'}</span>
								<Link to="/active-sliders" />
							</Menu.Item>

							<Menu.Item key="policies">
								<Icon type={BankOutlined} />
								<span>{'Politikalar'}</span>
								<Link to="/policies" />
							</Menu.Item>

							<Menu.Item key="reports">
								<Icon type={BankOutlined} />
								<span>{'Raporlar'}</span>
								<Link to="/reports" />
							</Menu.Item>

							<Menu.Item key="settings">
								<Icon type={SettingOutlined} />
								<span>{'Ayarlar'}</span>
								<Link to="/settings" />
							</Menu.Item>
						</Menu>
					</WebSideMenu>
				</div>
			)}
		</>
	);
};
export default SideMenu;
