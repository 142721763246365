import React, { Component } from 'react';
import { Drawer } from 'antd';

/**
 *
 * @param visible
 * @param close
 * @param name
 * @param surname
 * @param username
 * @param email
 * @param callCenter
 * @param role
 * @returns {JSX.Element}
 * @constructor
 */
const UserDraver = ({
                        visible,
                        close,
                        role,
                        fullname,
                        username,
                        email,
                        createdAt,
                        gender,
                        id
    }) => {

    return(
        <>
            <Drawer
                width={400}
                placement="right"
                onClose={close}
                closable={false}
                visible={visible}
            >
                <div className="">
                    <h6 className="text-muted text-uppercase mb-3">
                        Kullanıcı Bilgileri
                    </h6>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Id: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{id}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>İsim Soyisim: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{fullname}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Kullanıcı Adı: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{username}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Rol: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{role}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Email: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{email}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Cinsiyet: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{gender == 1 ? 'Erkek' : 'Kadın'}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Kayıt Tarihi: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{createdAt}</span>
                    </p>
                </div>
            </Drawer>
        </>
    )
}
export default UserDraver