import { $axios } from "../Base/base.services";

/**
 *
 * @param formData
 * @param categoryId
 * @returns {Promise<unknown>}
 */
export const updateCategory = (formData, categoryId) =>
    new Promise((resolve, reject) => {
        $axios
            .patch('/categories/' + categoryId, formData)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log('error:', err);
                reject(err.response.data);
            })
    })

/**
 *
 * @param formData
 * @returns {Promise<unknown>}
 */
export const createCategory = (formData) =>
    new Promise((resolve, reject) => {
        $axios
            .post('/categories/', formData)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log('error:', err);
                reject(err.response.data);
            })
    })