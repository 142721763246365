import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import {instanceApi} from "../../../services/Base/base.instance.services";
import { device } from "../../../utils/device";
import {createPolicy, createTerm} from "../../../services/Policy";
import TextArea from "antd/es/input/TextArea";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;
	
	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;
	
	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const CreateTermOfUseForm = () => {

    const [ loading, setLoading ]               = useState(false);
    const [ languageValue, setLanguageValue ]   = useState();

    const onFinish = values => {
        setLoading(true);

        const term = {
            title: values.title,
            content: values.content,
            language: languageValue
        };

        createTerm(term)
            .then(res => {
                setLoading(false);
                window.location = '/terms-of-use';
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                notification.error({
                    message: err.message
                })
            });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
        notification.error({
            message: `Bilgileri kontrol edip tekrar deneyiniz!`,
        });
    };

    const languageValueOnChange = event => {
        setLanguageValue(event);
    }

    return(
        <>
            <FormContainer>
                <Form
                    layout="vertical"
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <Form.Item
                        name="title"
                        label="Başlık"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Başlık alanını girin.',
                            },
                        ]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="content"
                        label="İçerik"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen içerik alanını girin.',
                            }
                        ]}>
                        <TextArea rows={'20'}/>
                    </Form.Item>

                    <Form.Item
                        name="language"
                        label="Dil"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen dil alanını girin.',
                            }
                        ]}>
                        <Select
                            value={languageValue}
                            onChange={languageValueOnChange}>
                            <Option value={'tr'}>tr</Option>
                            <Option value={'en'}>en</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <ButtonSections>
                            <PrimaryButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}>
                                    Oluştur
                                </Button>
                            </PrimaryButton>
                            <DefaultButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="default"
                                    href={'/terms-of-use'}>
                                    Geri
                                </Button>
                            </DefaultButton>
                        </ButtonSections>
                    </Form.Item>

                </Form>
            </FormContainer>
        </>
    )
}

export default CreateTermOfUseForm