import React from 'react';

export const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '414px',
    tablet: '768px',
    ipad: '1024px',
    ipadL: '1170px',
    laptopL: '1440px',
    desktop: '2560px',
    chrome: '765px',
};
