import React from 'react';
import styled from 'styled-components';
import { CreateNewUserForm } from '../../molecules/Forms';
import { Card } from "antd";
import { device } from "../../../utils/device";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSection = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Title = styled.div`
`;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleText = styled.h2`
	
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormSection = styled.div`
	margin-top: 20px;
	
	@media ${device.mobileL} {
		margin-right: 15px;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CreateUser = () => {

	return (
		<>
			<Container>
				<TopSection>
					<TitleSection>
						<Title>
							<TitleText>Yeni Kullanıcı Oluştur</TitleText>
						</Title>
					</TitleSection>
				</TopSection>
				<FormSection>
					<Card>
						<CreateNewUserForm />
					</Card>
				</FormSection>
			</Container>
		</>
	);
};
export default CreateUser;
