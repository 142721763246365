import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL
	? process.env.REACT_APP_API_URL
	: 'https://api.cizgi.studio/v1/';

export const baseUrl = apiUrl;
export const $axios = axios.create();
$axios.defaults.baseURL = baseUrl;
$axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
	'token'
)}`;
$axios.defaults.headers.common['cache-control'] = `no-cache`;

export const setToken = token => {
	$axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

$axios.interceptors.response.use(
	response => response,
	error => {
		if (error.response && error.response.status === 401) {
			localStorage.removeItem('token');
			localStorage.removeItem('authUser');
		}
		return Promise.reject(error);
	}
);
