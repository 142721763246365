import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Pagination,
} from 'antd';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveSliderTable = () => {
	const [data, setData] = useState();
	const [total, setTotal] = useState();
	const { confirm } = Modal;

	const editSlider = sliderId => {
		window.location = '/slider/edit/' + sliderId;
	};

	const deleteSlider = sliderId => {
		confirm({
			title: `Bu slider'ı silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/slides/' + sliderId)
					.then(res => {
						window.location = '/sliders';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const columns = [
		{
			title: `Id`,
			dataIndex: 'id',
		},
		{
			title: 'Başlangıç Tarihi',
			dataIndex: 'startDate',
		},
		{
			title: 'Dil',
			dataIndex: 'language',
			render: lang => {
				const lookup = { tr: 'Turkce', en: 'English' };

				return <>{lookup[lang]}</>;
			},
		},
		{
			title: 'Bitiş Tarihi',
			dataIndex: 'endDate',
		},
		{
			title: 'Tip',
			dataIndex: 'subjectType',
		},
		{
			title: `Resim`,
			dataIndex: 'image',
			render: image => <img src={image} style={{ width: '70px' }} />,
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Düzenle'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5"
							icon={<EditOutlined />}
							onClick={() => {
								editSlider(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteSlider(elm.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const fetchSliders = () => {
		instanceApi()
			.get('/slides/active')
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		fetchSliders();
	}, []);

	const paginationOnChange = event => {
		instanceApi()
			.get('/slides/active?page=' + event)
			.then(res => {
				setData(res.data.results);
			})
			.catch(err => console.log(err));
	};

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={1}
						defaultPageSize={10}
						total={total}
						onChange={paginationOnChange}
					/>
				</div>
			</Card>
		</>
	);
};
export default ActiveSliderTable;

