import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {instanceApi} from "../../../services/Base/base.instance.services";
import {Button, Card, Form, Input, InputNumber, Modal, notification, Pagination, Table, Tooltip} from "antd";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {device} from "../../../utils/device";
import TextArea from "antd/es/input/TextArea";
import {updateHomeList} from '../../../services/HomeList';

const HomeListHeaderEpisode = styled.div`
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #eeeeee;
`;

const HomeListHeaderLineEpisode = styled.div`
    display: flex;
`;

const HomeListLeftEpisode = styled.div`
    width: 50%;
`;

const HomeListRightEpisode = styled.div`
    margin-left: 10px;
    width: 50%;
`;

const HomeListTitleSection = styled.div`
`;

const HomeListDescriptionSection = styled.div`
`;

const WorksTableEpisode = styled.div`
    margin-top: 15px;
`;

const ButtonEpisode = styled.div`
    width: 30%;
    padding: 15px;
`;

const FilterEpisode = styled.div`
    width: 100%;
    padding: 15px;
`;

const FilterFormLine = styled.div`
    width: 100%;
    display: flex;
`;

const FilterSearchButtonEpisode = styled.div`
    margin-left: auto;
    margin-top: 30px;
`;

const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;

const PrimaryButton = styled.div`
    margin-left: 15px;
`;


const AddWorksToHomeListInfoCard = ({ id }) => {
    const [ title, setTitle ]                           = useState();
    const [ description, setDescription ]               = useState();
    const [ isActive, setIsActive ]                     = useState();
    const [ order, setOrder ]                           = useState();
    const [ works, setWorks ]                           = useState([]);

    const [ data, setData ]                             = useState([]);
    const [ total, setTotal ]                           = useState();
    const [ filterLoading, setFilterLoading ]           = useState(false);

    const [ modalVisible, setModalVisible ]             = useState(false);
    const [ loading, setLoading ]                       = useState(false);
    const [ selectedRowId, setSelectedRowId ]           = useState();

    const handleCancel = () => {
        setModalVisible(false);
    }

    const columns = [
        {
            title: `Başlık`,
            dataIndex: ['title','tr'],
        },
        {
            title: `Kategori`,
            dataIndex: ['category','title',['tr']],
        },
        {
            title: `Yayın Türü`,
            dataIndex: 'publicationType',
        },
        {
            title: "Kitay Tipi",
            dataIndex: 'workType'
        },
        {
            title: "Beğeni Sayısı",
            dataIndex: 'likesCount'
        },
        {
            title: "Okunma Sayısı",
            dataIndex: 'readingCount'
        },
        {
            title: "Yorum Sayısı",
            dataIndex: 'commentCount'
        },
        {
            title: "Değerlendirme Sayısı",
            dataIndex: 'totalRatingCount'
        },
        {
            title: "Tarih",
            dataIndex: 'createdAt'
        }
    ];

    const fetchBooksTotal = () => {
        instanceApi()
            .get('/works?limit=20')
            .then(res => {
                setTotal(res.data.totalResults);
                setData(res.data.results)
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        fetchHomeListDetails();
        fetchBooksTotal();
    },[]);

    const paginationOnChange = (event) => {
        instanceApi()
            .get('/works?page=' + event)
            .then(res => {
                setData(res.data.results);
            })
            .catch(err => console.log(err));
    }

    const fetchHomeListDetails = () => {
        instanceApi()
            .get('/home-lists/' + id)
            .then(res => {
                setTitle(res.data.title);
                setDescription(res.data.description);
                setIsActive(res.data.isActive);
                setOrder(res.data.order);
                res.data.works.map(work => {
                    const data = {
                        order: work.order,
                        work: work.work.id,
                    };
                    works.push(data);
                })
                if (res) {
                    console.log("works result: ", works);
                }
            })
            .catch(err => {
                console.log("err: ", err);
            })
    };

    const rowSelection = {
        onChange: (selectedRowKeys) => {
            console.log("rows: ", selectedRowKeys);
            setSelectedRowId(selectedRowKeys);
            setModalVisible(true);
        },
    };

    const filterFormOnFinish = values => {
        setFilterLoading(true);
        instanceApi()
            .get('/works/search?query=' + values.search)
            .then(res => {
                setData(res.data.results);
                setFilterLoading(false);
            })
            .catch(err => {
                setFilterLoading(false);
                console.log("err", err);
            });
    }

    const filterFormOnFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        notification.error({
            message: `Bilgileri kontrol edip tekrar deneyiniz!`,
        });
    }

    const clearFilterData = () => {
        fetchBooksTotal();
    }

    const onFinish = (values) => {
        //console.log("order: ", values.order);
        const data = {
            order: values.order,
            work: selectedRowId.toString()
        };

        works.push(data);

        console.log("result works: ", works);

        updateHomeList(id,works)
            .then(res => {
                window.location = '/home-lists/' + id;
            })
            .catch(err => {
                console.log("err: ", err);
            })

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        notification.error({
            message: `Bilgileri kontrol edip tekrar deneyiniz!`,
        });
    }

    return (
        <>

            <Modal
                title="Anasayfa Listesi İçin Eser Ekle"
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}>

                <Form
                    layout="vertical"
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                        name="order"
                        label="Sıra"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen sıra alanını girin.',
                            }
                        ]}>
                        <InputNumber style={{ width: "100%"}}/>
                    </Form.Item>

                    <Form.Item>
                        <ButtonSections>
                            <PrimaryButton>
                                <Button
                                    style={{ width: '200px' }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}>
                                    Oluştur
                                </Button>
                            </PrimaryButton>
                        </ButtonSections>
                    </Form.Item>
                </Form>
            </Modal>


            <HomeListHeaderEpisode>
                <HomeListHeaderLineEpisode>
                    <HomeListLeftEpisode>
                        <HomeListTitleSection>
                            <h4>Başlık</h4>
                        </HomeListTitleSection>
                        <HomeListDescriptionSection>
                            <p>{title}</p>
                        </HomeListDescriptionSection>
                    </HomeListLeftEpisode>
                    <HomeListRightEpisode>
                        <HomeListTitleSection>
                            <h4>Açıklama</h4>
                        </HomeListTitleSection>
                        <HomeListDescriptionSection>
                            {description}
                        </HomeListDescriptionSection>
                    </HomeListRightEpisode>
                </HomeListHeaderLineEpisode>
                <HomeListHeaderLineEpisode>
                    <HomeListLeftEpisode>
                        <HomeListTitleSection>
                            <h4>Durum</h4>
                        </HomeListTitleSection>
                        <HomeListDescriptionSection>
                            <p>{isActive ? 'Aktif' : 'Pasif'}</p>
                        </HomeListDescriptionSection>
                    </HomeListLeftEpisode>
                    <HomeListRightEpisode>
                        <HomeListTitleSection>
                            <h4>Sıra</h4>
                        </HomeListTitleSection>
                        <HomeListDescriptionSection>
                            <p>{order}</p>
                        </HomeListDescriptionSection>
                    </HomeListRightEpisode>
                </HomeListHeaderLineEpisode>
            </HomeListHeaderEpisode>

            <WorksTableEpisode>
                <FilterEpisode>
                    <Card>
                        <Form
                            layout="vertical"
                            name="filter-form"
                            onFinish={filterFormOnFinish}
                            onFinishFailed={filterFormOnFinishFailed}>

                            <FilterFormLine>
                                <Form.Item
                                    name="search"
                                    label="Eser İsmine Göre Ara"
                                    style={{ width: "300px"}}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Lütfen Arama alanını girin"
                                        }
                                    ]}>
                                    <Input/>
                                </Form.Item>
                                <FilterSearchButtonEpisode>
                                    <Form.Item>
                                        <ButtonSections>
                                            <PrimaryButton>
                                                <Button
                                                    style={{ width: "180px"}}
                                                    type="default"
                                                    htmlType="submit"
                                                    loading={filterLoading}>
                                                    Ara
                                                </Button>
                                            </PrimaryButton>
                                            <PrimaryButton>
                                                <Button
                                                    style={{ width: "180px"}}
                                                    type="default"
                                                    onClick={clearFilterData}
                                                >
                                                    Temizle
                                                </Button>
                                            </PrimaryButton>
                                        </ButtonSections>
                                    </Form.Item>
                                </FilterSearchButtonEpisode>
                            </FilterFormLine>
                        </Form>
                    </Card>
                </FilterEpisode>
                <Card>
                    <div className={"table-responsive"}>
                        <Table
                            rowSelection={rowSelection}
                            rowKey="id"
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                        />
                        <Pagination
                            style={{ padding: "15px"}}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            total={total}
                            onChange={paginationOnChange}/>
                    </div>
                </Card>
            </WorksTableEpisode>
        </>
    )
}
export default AddWorksToHomeListInfoCard;