import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import { instanceApi } from "../../../services/Base/base.instance.services";
import { updateUser, showUserAsPageActive, showUserAsPageInactive } from "../../../services/User";
import { device } from "../../../utils/device";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;
	
	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;
	
	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const EditUserDetailForm = ({ id }) => {

    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState();
    const [email, setEmail] = useState();
    const [username, setUsername] = useState();
    const [role, setRole] = useState();
    const [roleTypeValue, setRoleTypeValue] = useState();
    const [hasPublishedAtShowcase, setHasPublishedAtShowcase] = useState();

    useEffect(() => {
        fetchUserDetail();
    }, []);

    const fetchUserDetail = () => {
        instanceApi()
            .get('/users/' + id)
            .then(res => {
                setFullName(res.data.fullname);
                setEmail(res.data.email);
                setUsername(res.data.username);
                setRole(res.data.role);
                setHasPublishedAtShowcase(res.data.hasPublishedAtShowcase);
            })
            .catch(err => console.log(err));
    }

    const onFinish = values => {
        setLoading(true);
        console.log(values)
        const user = {
            fullname: values.fullname,
            email: values.email,
            username: values.username,
            password: values.password,
            role: values.role
        };

        updateUser(user, id)
            .then(res => {
                setLoading(false);
                console.log("res: ", res);
                window.location = '/user/edit/' + id;
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                notification.error({
                    message: err.message
                })
            });

    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
        notification.error({
            message: `Bilgileri kontrol edip tekrar deneyiniz!`,
        });
    };

    const roleTypeValueOnChange = event => {
        setRoleTypeValue(event);
    }

    const activeShowUserHandleClick = () => {
        showUserAsPageActive(id)
            .then(res => {
                window.location = '/user/edit/' + id;
            })
            .catch(err => {
                console.log("err: ", err);
            })
    }

    const inactiveShowUserHandleClick = () => {
        showUserAsPageInactive(id)
            .then(res => {
                window.location = '/user/edit/' + id;
            })
            .catch(err => {
                console.log("err: ", err);
            })
    }

    return (
        <>
            <FormContainer>
                <Form
                    layout="vertical"
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <Form.Item
                        name="fullname"
                        label="İsim Soyisim"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen isim soyisim alanını girin.',
                            },
                        ]}>
                        <Input placeholder={fullName} />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen Email alanını girin.',
                            },
                            {
                                type: 'email',
                                message: 'Lütfen geçerli bir email alanı girin.',
                            },
                        ]}>
                        <Input placeholder={email} />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        label="Kullanıcı Adı"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen Kullanıcı Adı alanını girin.',
                            }
                        ]}>
                        <Input
                            placeholder={username}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Şifre"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen Şifre alanını girin.',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label="Rol"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen Rol Seçin.',
                            },
                        ]}>
                        <Select
                            value={roleTypeValue}
                            onChange={roleTypeValueOnChange}
                            placeholder={role}
                        >
                            <Option value={'user'}>Normal Kullanıcı</Option>
                            <Option value={'admin'}>Admin Kullanıcısı</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <ButtonSections>
                            <PrimaryButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}>
                                    Güncelle
                                </Button>
                            </PrimaryButton>
                            <DefaultButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="default"
                                    href={'/dashboard'}
                                    htmlType="submit">
                                    Geri
                                </Button>
                            </DefaultButton>
                            {hasPublishedAtShowcase ? (
                                <>
                                    <DefaultButton>
                                        <Button
                                            type="default"
                                            onClick={inactiveShowUserHandleClick}>
                                            Vitrin Kullanıcısından Çıkar
                                        </Button>
                                    </DefaultButton>
                                </>
                            ) : (
                                <DefaultButton>
                                    <Button
                                        type="default"
                                        onClick={activeShowUserHandleClick}>
                                        Vitrin Kullanıcısı Yap
                                    </Button>
                                </DefaultButton>
                            )}
                        </ButtonSections>
                    </Form.Item>

                </Form>
            </FormContainer>
        </>
    )
}

export default EditUserDetailForm