const { Modal } = require('antd');

const ImageViewer = ({ modalVisible, setModalVisible, link }) => {
	return (
		<Modal
			visible={modalVisible}
			onCancel={() => setModalVisible(false)}
			footer={null}>
			<img
				style={{
					width: '100%',
					height: '100%',
					cursor: 'pointer',
					objectFit: 'contain',
				}}
				src={link}
			/>
		</Modal>
	);
};
export default ImageViewer;
