import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Table,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
	Avatar,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { UserDraver } from '../../Draver';
import { Pagination } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../utils/device';
import Loading from 'components/shared-components/Loading';

const UserNameWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const UserName = styled.div`
	margin-left: 10px;
`;
const ControlButtonWrapper = styled.div`
	gap: 5px;
	display: flex;
	justify-content: flex-end;
`;

const statusConfig = {
	rejected: {
		label: 'Reddedildi',
		color: 'red',
	},
	approved: {
		label: 'Onaylandı',
		color: 'green',
	},
	pending: {
		label: 'Bekliyor',
		color: 'orange',
	},
};

const MonetizationTable = () => {
	const [data, setData] = useState();
	const [total, setTotal] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const { confirm } = Modal;
	const [getPage, setPage] = useState(1);

	//When approve it
	const handleOnPressApproveButton = data => {
		confirm({
			title: `Onaylamak istediğine emin misin?`,
			okText: `Evet`,
			okType: 'primary',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.post('/monetization-requests/' + data.id + '/approve')
					.then(res => {
						window.location = '/monetizations';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	//When rejected
	const handleOnPressRejectButton = data => {
		confirm({
			title: `Reddetmek istediğine emin misin?`,
			okText: `Evet`,
			okType: 'primary',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.post('/monetization-requests/' + data.id + '/reject')
					.then(res => {
						window.location = '/monetizations';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	//Load all the data
	const getAllMonetizationsFromServer = () => {
		setIsLoading(true);
		instanceApi()
			.get('monetization-requests?limit=20&page=' + getPage)
			.then(res => {
				setData(res.data.results);
				setTotal(res.data.totalResults);
				setIsLoading(false);
			})
			.catch(err => {
				console.log(err);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getAllMonetizationsFromServer();
	}, [getPage]);

	const paginationOnChange = event => {
		setPage(event);
	};

	console.log(data);

	const columns = [
		{
			title: `İsim`,
			render: (_, data) => {
				return (
					<UserNameWrapper>
						<Avatar src={data.user.thumbnail} />
						<UserName>
							<UserName>
								{data.user.fullname} (@{data.user.username})
							</UserName>
							<UserName>{data.user.email}</UserName>
							<UserName>{data.user.realFullName}</UserName>
						</UserName>
					</UserNameWrapper>
				);
			},
		},
		{
			title: `Bakiye`,
			render: (_, data) => {
				return `${data.balance}`;
			},
		},
		{
			title: `Yatırılacak Tutar`,
			render: (_, data) => {
				return `${data.amount} ₺`;
			},
		},
		{
			title: `IBAN`,
			render: (_, data) => {
				return `${data.user.iban}`;
			},
		},
		{
			title: `Adres`,
			render: (_, data) => {
				return `${data.user.address}`;
			},
		},
		{
			title: `TC`,
			render: (_, data) => {
				return `${data.user.tc}`;
			},
		},
		{
			title: `Tarih`,
			dataIndex: 'date',
		},
		{
			title: `Durum`,
			dataIndex: 'status',
			render: (_, data) => {
				return (
					<span
						style={{
							color: statusConfig[data.status].color,
							fontWeight: 'bold',
						}}>
						{statusConfig[data.status].label}
					</span>
				);
			},
		},
		{
			title: 'İşlemler',
			dataIndex: 'actions',
			render: (data, elm) => {
				if (elm.status !== 'pending') return null;
				return (
					<ControlButtonWrapper>
						<Tooltip title={'Onayla'}>
							<Button
								type="primary"
								className="mr-2"
								icon={<CheckOutlined />}
								onClick={() => handleOnPressApproveButton(elm)}
								size="small">
								Onayla
							</Button>
						</Tooltip>

						<Tooltip title={'Reddet'}>
							<Button
								type="danger"
								className="mr-2"
								icon={<CloseOutlined />}
								onClick={() => handleOnPressRejectButton(elm)}
								size="small"
							/>
						</Tooltip>
					</ControlButtonWrapper>
				);
			},
		},
	];

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive overflow-scroll">
					{!isLoading && (
						<Table
							columns={columns}
							dataSource={data}
							pagination={false}
						/>
					)}
					{isLoading && <Loading text="yükleniyor..." />}
					{!isLoading && data?.length > 0 && (
						<Pagination
							style={{ padding: '15px' }}
							defaultCurrent={1}
							defaultPageSize={20}
							total={total}
							onChange={paginationOnChange}
						/>
					)}
				</div>
			</Card>
		</>
	);
};
export default MonetizationTable;
