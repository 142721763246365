import React, { useEffect, useState } from 'react';
import {
	Button,
	Form,
	Image,
	Input,
	message,
	notification,
	Upload,
} from 'antd';
import styled from 'styled-components';
import { device } from '../../../utils/device';
import { updateCategory } from '../../../services/Category';
import { instanceApi } from '../../../services/Base/base.instance.services';
import { UploadOutlined } from '@ant-design/icons';
import { jsonToFormData } from 'utils';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	margin-top: 20px;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const EditCategoryDetailForm = ({ id }) => {
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState();
	const [titleColor, setTitleColor] = useState('');
	const [icon, setIcon] = useState();
	const [color, setColor] = useState('');
	const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [iconImage, setIconImage] = useState(null);
	const [iconImageUrl, setIconImageUrl] = useState('');
	const [uploadingIcon, setUploadingIcon] = useState(false);
	const [getDetails, setDetails] = useState();

	const onFinish = values => {
		setLoading(true);

		const data = {};
		if (icon) {
			data.icon = icon;
		}
		if (color) {
			data.color = color;
		}
		if (titleColor) {
			data.titleColor = titleColor;
		}
		if (backgroundImageUrl) {
			data.backgroundImage = backgroundImageUrl;
		}
		if (title) {
			data.title = { en: values.engTitle, tr: values.title };
		}
		if (iconImageUrl) {
			data.iconImage = iconImageUrl;
		}

		const formData = jsonToFormData(data);
		updateCategory(formData, id)
			.then(res => {
				setLoading(false);
				window.location = '/categories';
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
				notification.error({
					message: err.message,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const fileSelectedHandler = event => {
		setIcon(event.target.files[0]);
	};

	useEffect(() => {
		fetchCategoryDetail(id);
	}, []);

	const fetchCategoryDetail = id => {
		instanceApi()
			.get('/categories/' + id)
			.then(res => {
				setTitle(res.data.title);
				setColor(res.data.color);
				setTitleColor(res.data.titleColor);
				setBackgroundImageUrl(res.data.backgroundImage);
				setIconImageUrl(res.data.iconImage);
				setDetails(res.data);
			})
			.catch(err => console.log(err));
	};

	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('image', backgroundImage);
		setUploading(true);

		try {
			const { data } = await instanceApi().post(
				`/upload/image`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (data.url) {
				setBackgroundImageUrl(data.url);
				message.success('Başarıyla yüklendi');
			}
		} catch (error) {
			message.error('Dosya yüklenirken bir hata oluştu.');
		} finally {
			setUploading(false);
		}
	};

	const handleUploadIcon = async () => {
		const formData = new FormData();
		formData.append('image', iconImage);
		setUploadingIcon(true);

		try {
			const { data } = await instanceApi().post(
				`/upload/image`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (data.url) {
				setIconImageUrl(data.url);
				message.success('Başarıyla yüklendi');
			}
		} catch (error) {
			message.error('Dosya yüklenirken bir hata oluştu.');
		} finally {
			setUploadingIcon(false);
		}
	};

	const uploadProps = {
		onRemove: file => {
			setBackgroundImage(null);
		},
		beforeUpload: file => {
			setBackgroundImage(file);
			return false;
		},
		file: backgroundImage,
	};

	const uploadPropsIcon = {
		onRemove: file => {
			setIconImage(null);
		},
		beforeUpload: file => {
			setIconImage(file);
			return false;
		},
		file: iconImage,
	};

	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="title"
						label="Başlık"
						rules={[
							{
								required: false,
								message: 'Lütfen başlık girin.',
							},
						]}>
						<Input
							placeholder={title?.tr}
							onChange={e => setTitle(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						name="engTitle"
						label="English title"
						rules={[
							{
								required: false,
								message: 'Enter the title in English.',
							},
						]}>
						<Input
							placeholder={title?.en}
							onChange={e => setTitle(e.target.value)}
						/>
					</Form.Item>
					<input
						style={{ width: '100%', marginTop: '30px' }}
						type="file"
						onChange={fileSelectedHandler}
						className="custom-file-input"
					/>
					<Form.Item
						name="color"
						label="Renk"
						rules={[
							{
								required: false,
								message: 'Lütfen renk girin.',
							},
						]}>
						<Input
							placeholder={color}
							value={color}
							onChange={e => setColor(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						name="titleColor"
						label="Başlık Rengi"
						rules={[
							{
								required: false,
								message: 'Lütfen renk girin.',
							},
						]}>
						<Input
							placeholder={titleColor}
							value={titleColor}
							defaultValue={titleColor}
							onChange={e => setTitleColor(e.target.value)}
						/>
					</Form.Item>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Arkaplan Resmi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							<div>
								<Upload
									accept="image/png,image/jpeg,image/jpg"
									{...uploadProps}>
									<Button icon={<UploadOutlined />}>
										Resim Seç
									</Button>
								</Upload>
								{backgroundImageUrl || backgroundImage ? (
									<Image
										width={100}
										src={
											backgroundImageUrl ||
											URL.createObjectURL(backgroundImage)
										}
									/>
								) : (
									<p>Seçilmedi</p>
								)}
								{!!backgroundImage && (
									<Button
										type="primary"
										onClick={handleUpload}
										loading={uploading}
										style={{ marginTop: 16 }}>
										{uploading ? 'Yükleniyor...' : 'Yükle'}
									</Button>
								)}
							</div>
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							İkon Resmi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							<div>
								<Upload
									accept="image/png,image/jpeg,image/jpg"
									{...uploadPropsIcon}>
									<Button icon={<UploadOutlined />}>
										Resim Seç
									</Button>
								</Upload>
								{iconImageUrl || iconImage ? (
									<Image
										width={100}
										src={
											iconImageUrl ||
											URL.createObjectURL(iconImage)
										}
									/>
								) : (
									<p>Seçilmedi</p>
								)}
								{!!iconImage && (
									<Button
										type="primary"
										onClick={handleUploadIcon}
										loading={uploadingIcon}
										style={{ marginTop: 16 }}>
										{uploadingIcon
											? 'Yükleniyor...'
											: 'Yükle'}
									</Button>
								)}
							</div>
						</span>
					</p>
					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Güncelle
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/categories'}
									htmlType="submit">
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};
export default EditCategoryDetailForm;
