import { $axios } from '../Base/base.services';

/**
 *
 * @param formData
 * @param sliderId
 * @returns {Promise<unknown>}
 */
export const updateSlider = (formData, sliderId) =>
	new Promise((resolve, reject) => {
		$axios
			.patch('/slides/' + sliderId, formData)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('error:', err);
				reject(err.response.data);
			});
	});

/**
 *
 * @param formData
 * @returns {Promise<unknown>}
 */
export const createSlider = formData =>
	new Promise((resolve, reject) => {
		$axios
			.post('/slides/', formData)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('error:', err);
				reject(err.response.data);
			});
	});

//returns the details of the slider given id
export const getSliderById = id =>
	new Promise((resolve, reject) => {
		$axios
			.get('/slides/' + id)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				console.log('error:', err);
				reject(err.response.data);
			});
	});

