import { $axios } from '../Base/base.services';

/**
 *
 * @param policy
 * @returns {Promise<unknown>}
 */
export const createPolicy = (policy) =>
    new Promise((resolve, reject) => {
        $axios
            .post('/policies', {
                type: policy.type,
                title: policy.title,
                content: policy.content,
                language: policy.language
            })
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                console.log('error:', error);
                reject(error.response.data);
            });
    });

/**
 *
 * @param term
 * @returns {Promise<unknown>}
 */
export const createTerm = (term) =>
    new Promise((resolve, reject) => {
        $axios
            .post('/policies', {
                type: 'terms',
                title: term.title,
                content: term.content,
                language: term.language
            })
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                console.log('error:', error);
                reject(error.response.data);
            });
    });

/**
 *
 * @param policy
 * @param id
 * @returns {Promise<unknown>}
 */
export const updatePolicy = (policy,id) =>
    new Promise((resolve, reject) => {
        $axios
            .patch('/policies/' + id,{
                title: policy.title,
                content: policy.content
            })
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                console.log('error:', error);
                reject(error.response.data);
            });
    });

/**
 *
 * @param term
 * @param id
 * @returns {Promise<unknown>}
 */
export const updateTerm = (term,id) =>
    new Promise((resolve, reject) => {
        $axios
            .patch('/policies/' + id,{
                title: term.title,
                content: term.content
            })
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                console.log('error:', error);
                reject(error.response.data);
            });
    });