export const linkRegex =
	/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

//finds links,
//img links ets..
export const getConentImages = (content, onClick) => {
	const links = content.match(linkRegex);

	return links?.map((link, index) => {
		return (
			<img
				alt={index}
				key={index}
				src={link}
				width="100px"
				onClick={() => onClick(link)}
				style={{ borderRadius: 10, cursor: 'pointer' }}
			/>
		);
	});
};
export const waitPlease = ms => new Promise(resolve => setTimeout(resolve, ms));

//removes link
//shortened the postconent
export const prettyPostContent = content => {
	let rawContent = content;

	//remove links
	rawContent = rawContent.replace(linkRegex, '');

	//put dots at the end
	const contentLength = rawContent.length;
	rawContent = rawContent.split(' ').slice(0, 20).join(' ');
	if (contentLength) {
		rawContent = content.trim() + '...';
	}

	return rawContent;
};

export const buildFormData = (formData, data, parentKey) => {
	if (
		data &&
		typeof data === 'object' &&
		!(data instanceof Date) &&
		!(data instanceof File) &&
		!(data instanceof Blob)
	) {
		Object.keys(data).forEach(key => {
			buildFormData(
				formData,
				data[key],
				parentKey ? `${parentKey}[${key}]` : key
			);
		});
	} else {
		const value = data == null ? '' : data;

		formData.append(parentKey, value);
	}
};

export const jsonToFormData = data => {
	const formData = new FormData();
	buildFormData(formData, data);
	return formData;
};
