import React from 'react';
import styled from 'styled-components';
import { EditProfileSettingForm } from "../../molecules/Forms";
import { Card } from "antd";
import {device} from "../../../utils/device";
import theme from "../../../theme";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSection = styled.div`
	display: block;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Title = styled.div`
`;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleText = styled.h2`
	
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescription = styled.div`
`;

/**
 *
 * @type {StyledComponent<"p", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescriptionText = styled.label`
	font-size: 15px;
	color: ${theme.lightBlue};
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormSection = styled.div`
	margin-top: 20px;
	
	@media ${device.mobileL} {
		margin-right: 15px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const EditSetting = () => {

    return(
        <>
            <Container>
                <TopSection>
                    <TitleSection>
                        <Title>
                            <TitleText>Profil Düzenle</TitleText>
                        </Title>
                        <TitleDescription>
                            <TitleDescriptionText>Bu sayfa üzerinden profil bilgilerinizi düzenleyebilirsiniz.</TitleDescriptionText>
                        </TitleDescription>
                    </TitleSection>
                </TopSection>
                <FormSection>
                    <Card>
                        <EditProfileSettingForm/>
                    </Card>
                </FormSection>
            </Container>
        </>
    )
}
export default EditSetting