import React, {useState} from "react";
import { Button, Form, Input, notification, Select } from 'antd';
import styled from "styled-components";
import { Option } from 'antd/es/mentions';
import { device } from "../../../utils/device";
import {updateAvatar} from "../../../services/Avatar";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;
	
	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	margin-top: 20px;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;
	
	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const EditAvatarDetailForm = ({ id }) => {

    const [ loading, setLoading ]                   = useState();
    const [ image, setImage ]                       = useState();
    const [ statusTypeValue, setStatusTypeValue ]   = useState();

    const onFinish = (values) => {

        setLoading(true);
        const formData = new FormData();
        formData.append('isActive', values.isActive);
        formData.append('image', image, image.name);

        updateAvatar(formData, id)
            .then(res => {
                setLoading(false);
                console.log("res",res);
                window.location = '/avatars';
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                notification.error({
                    message: err.message
                })
            });
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
        notification.error({
            message: `Bilgileri kontrol edip tekrar deneyiniz!`,
        });
    };

    const fileSelectedHandler = event => {
        //console.log("events: ", event.target.files[0]);
        setImage(event.target.files[0]);
    }

    const statusTypeValueOnChange = (event) => {
        setStatusTypeValue(event)
    }

    return(
        <>
            <FormContainer>
                <Form
                    layout="vertical"
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <Form.Item
                        name="isActive"
                        label="Durum"
                        rules={[
                            {
                                required: false,
                                message: 'Lütfen durum seçin.',
                            },
                        ]}>
                        <Select
                            value={statusTypeValue}
                            onChange={statusTypeValueOnChange}
                        >
                            <Option value={'true'}>Aktif</Option>
                            <Option value={'false'}>Pasif</Option>
                        </Select>
                    </Form.Item>

                    <input required style={{ width: "100%", marginTop: "30px"}} type="file" onChange={fileSelectedHandler} className="custom-file-input"/>

                    <Form.Item>
                        <ButtonSections>
                            <PrimaryButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}>
                                    Güncelle
                                </Button>
                            </PrimaryButton>
                            <DefaultButton>
                                <Button
                                    style={{ width: '180px' }}
                                    type="default"
                                    href={'/avatars'}
                                    htmlType="submit">
                                    Geri
                                </Button>
                            </DefaultButton>
                        </ButtonSections>
                    </Form.Item>

                </Form>
            </FormContainer>
        </>
    )
}
export default EditAvatarDetailForm