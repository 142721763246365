import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Tooltip, Modal, notification } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { Pagination } from 'antd';
import styled from 'styled-components';
import { PostDrawer } from 'components/molecules/Draver';
import { getConentImages, prettyPostContent } from 'utils';
import ImageViewer from 'components/common/ImageViewer';

const PostCategoryWrapper = styled.div`
	overflow: hidden;
`;

const PostCategoryItemStyle = styled.div`
	overflow: hidden;
	background-color: ${props => props.backgroundColor};
	border-radius: 10px;
	display: inline-block;
	color: ${props => props.color};
	padding: 1px 8px;
	margin-right: 5px;
`;

const StatusWrapperStyle = styled.div`
	overflow: hidden;
	background-color: ${props => props.backgroundColor};
	border-radius: 10px;
	display: inline-block;
	color: ${props => props.color};
	padding: 1px 8px;
	margin-right: 5px;
`;

const ModerationReportsTable = () => {
	const [data, setData] = useState();
	const [total, setTotal] = useState();

	const [visible, setVisible] = useState(false);
	const [role, setRole] = useState();
	const [fullName, setFullName] = useState();
	const [username, setUsername] = useState();
	const [email, setEmail] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [gender, setGender] = useState();
	const [id, setId] = useState();
	const [modalVisible, setModalVisible] = useState(false);
	const [getActivePostImage, setActivePostImage] = useState();
	const [getSearchKeyword, setSearchKeyword] = useState();
	const [getCurrentPage, setCurrentPage] = useState(1);
	const [selectedPost, setSelectedPost] = useState();

	const { confirm } = Modal;

	const RenderCategories = ({ reason }) => {
		const splitedReasons = reason.split(' ');
		const colors = {
			harassment: {
				background: 'crimson',
				color: 'white',
			},

			'self-harm': { color: 'black', background: 'yellow' },
			'self-harm/intent': { color: 'white', background: 'dodgerblue' },
			'hate/threatening': { color: 'white', background: 'green' },
			'harassment/threatening': { color: 'black', background: 'orange' },
			violence: { color: 'black', background: 'gray' },
			sexual: { color: 'white', background: 'purple' },
			hate: { color: 'black', background: 'teal' },
		};

		return splitedReasons.map(item => {
			const selectedColor = colors[item] || {
				color: 'white',
				background: 'black',
			};

			return (
				<PostCategoryItemStyle
					color={selectedColor.color}
					backgroundColor={selectedColor.background}>
					{item}
				</PostCategoryItemStyle>
			);
		});
	};
	const columns = [
		{
			title: `İsim`,
			dataIndex: 'user',
			render: user => {
				return (
					<div>
						<span>{user?.fullname} </span>
						<a
							href={`user/edit/${user?.id}`}>{`@${user?.username}`}</a>
					</div>
				);
			},
		},
		{
			title: `Kategori`,
			dataIndex: 'reason',
			render: reason => {
				return (
					<PostCategoryWrapper>
						<RenderCategories reason={reason} />
					</PostCategoryWrapper>
				);
			},
		},

		{
			title: `Gonderi`,
			dataIndex: 'content',
			render: (_, el) => {
				if (el.content)
					return (
						<div>
							<div>
								{getConentImages(
									el.content,
									handleOnClickImage
								)}
							</div>
							<div>{prettyPostContent(el.content)}</div>
						</div>
					);
			},
		},

		{
			title: 'Durum',
			dataIndex: 'isProcessed',
			render: (_, el) => {
				console.log('Elll-->', el);
				if (el.isProcessed) {
					return (
						<StatusWrapperStyle
							color="white"
							backgroundColor="green">
							Gozden Gecirildi
						</StatusWrapperStyle>
					);
				}
				return (
					<StatusWrapperStyle color="black" backgroundColor="yellow">
						Beklemede
					</StatusWrapperStyle>
				);
			},
		},

		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},

		{
			title: '#',
			dataIndex: 'actions',
			render: (_, elm) => {
				console.log('Ell=-<', elm);
				const { subjectWork, subjectPost, subjectEpisode } = elm;
				const isThereAnyThingToBeDeleted =
					subjectEpisode || subjectWork || subjectPost;
				return (
					<div
						className="text-right d-flex justify-content-end"
						style={{ gap: 4 }}>
						{isThereAnyThingToBeDeleted && (
							<DeleteButtonRender elm={elm} />
						)}
						<Tooltip title="Gozden Gecirildi Olarak isaretle">
							<Button
								type="primary"
								style={{ marginRight: '12px' }}
								icon={<EyeOutlined />}
								success
								onClick={() => setItAsModerated(elm?.id)}
								size="small">
								Gozden Gecirildi
							</Button>
						</Tooltip>
						<Tooltip title={'Görüntüle'}>
							<Button
								className="mr-2"
								icon={<EyeOutlined />}
								onClick={() => {
									setSelectedPost(elm);
									showUserDetail(elm?.user);
								}}
								size="small"
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const DeleteButtonRender = ({ elm }) => {
		const { subjectWork, subjectPost, subjectEpisode } = elm;
		const data = subjectEpisode || subjectWork || subjectPost;
		const lookup = {
			post: {
				buttonLabel: 'Gonderiyi Sil',
				data: data,
				type: 'post',
			},
			episode: {
				buttonLabel: 'Bolumu Sil',
				data: data,

				type: 'episode',
			},
			work: {
				buttonLabel: 'Eseri Sil',
				data: data,
				type: 'work',
			},
		};
		const type = subjectEpisode ? 'episode' : subjectWork ? 'work' : 'post';
		let willBeDeleted = lookup[type];

		return (
			<Tooltip title={willBeDeleted.buttonLabel}>
				<Button
					type="primary"
					style={{ marginRight: '12px' }}
					icon={<EyeOutlined />}
					danger
					onClick={() => deletePost(willBeDeleted, elm?.id)}
					size="small">
					{willBeDeleted.buttonLabel}
				</Button>
			</Tooltip>
		);
	};

	/**
	 * @param item the item that will be deleted, i could be a post, episode or work
	 */
	const deletePost = (item, moderationId) => {
		let url = '';
		if (item.type === 'post') url = '/posts/' + item.data.id;
		if (item.type === 'work') url = '/works/' + item.data.id;
		if (item.type === 'episode') url = '/episode/' + item.data.id;
		confirm({
			title: `Bu gonderiyi silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete(url)
					.then(res => {
						setItAsModerated(moderationId);
						fetchSocialPostFromServer();
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const setItAsModerated = moderationId => {
		const url = 'moderation/{id}/process'.replace('{id}', moderationId);
		instanceApi()
			.post(url)
			.then(() => {
				fetchSocialPostFromServer();
			})

			.catch(err => {
				console.log('err: ', err);
			});
	};

	useEffect(() => {
		fetchSocialPostFromServer();
	}, [getCurrentPage, getSearchKeyword]);

	//search for post
	const fetchSocialPostFromServer = values => {
		let url =
			'/moderation?sortBy=createdAt:desc&page=' +
			getCurrentPage +
			'&query=' +
			getSearchKeyword;

		if (!getSearchKeyword) {
			url = '/moderation?sortBy=createdAt:desc&page=' + getCurrentPage;
		}

		instanceApi()
			.get(url)
			.then(res => {
				console.log(res.data?.results);
				setData(res.data?.results);
				setTotal(res.data?.totalResults);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const fetchSocialPostFromServerFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const closeVisible = () => {
		setVisible(false);
	};

	const showUserDetail = user => {
		setVisible(true);
		setRole(user.role);
		setFullName(user.fullname);
		setUsername(user.username);
		setEmail(user.email);
		setCreatedAt(user.createdAt);
		setGender(user.gender);
		setId(user.id);
	};

	const handleOnClickImage = image => {
		setModalVisible(true);
		setActivePostImage(image);
	};

	return (
		<>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
					<Pagination
						style={{ padding: '15px' }}
						defaultCurrent={getCurrentPage}
						defaultPageSize={20}
						total={total}
						onChange={event => setCurrentPage(event)}
					/>
				</div>
				<PostDrawer
					visible={visible}
					close={closeVisible}
					role={role}
					fullname={fullName}
					username={username}
					email={email}
					createdAt={createdAt}
					gender={gender}
					id={id}
					post={selectedPost}
				/>
				<ImageViewer
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
					link={getActivePostImage}
				/>
			</Card>
		</>
	);
};
export default ModerationReportsTable;
