import React from 'react';
import LoginForm from '../../../molecules/Auth/Login';
import LoginInfoCard from '../../../molecules/Auth/LoginInfoCard';
import { Row, Col } from 'antd';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Login = () => {
	return (
		<>
			<div className={`h-100`}>
				<Row justify="center" className="align-items-stretch h-100">
					<LoginForm />
					<LoginInfoCard />
				</Row>
			</div>
		</>
	);
};
export default Login;
