import React from 'react';
import { Card } from 'antd';

/**
 *
 * @param title
 * @param value
 * @param subtitle
 * @param prefix
 * @returns {JSX.Element}
 * @constructor
 */
const StatisticCardWidget = ({ title, value, subtitle }) => {
	return (
		<>
			<Card>
				{title && <h4 className="mb-0">{title}</h4>}
				<div className={`${title ? 'mt-3' : ''}`}>
					<div>
						<div className="d-flex align-items-center">
							<h1 className="mb-0 font-weight-bold">{value}</h1>
						</div>
						{subtitle && (
							<div className="text-gray-light mt-1">
								{subtitle}
							</div>
						)}
					</div>
				</div>
			</Card>
		</>
	);
};
export default StatisticCardWidget;
