import React from "react";
import { Drawer } from "antd";


const PolicyDraver = ({
                        visible,
                        close,
                        id,
                        type,
                        title,
                        content,
                        language,
                        date
                      }) => {
    return(
        <>
            <Drawer
                width={400}
                placement="right"
                onClose={close}
                closable={false}
                visible={visible}
            >
                <div className="">
                    <h6 className="text-muted text-uppercase mb-3">
                        Detay
                    </h6>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Id: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{id}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Tip: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{type}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Başlık: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{title}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Dil: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{language}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>Tarih: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{date}</span>
                    </p>
                    <p>
                        <span className="text-muted" style={{ marginLeft: 10}}>İçerik: </span>
                        <span className="text-dark" style={{ marginLeft: 10}}>{content}</span>
                    </p>
                </div>
            </Drawer>
        </>
    )
}
export default PolicyDraver