import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { device } from "../../../utils/device";
import { useTranslation } from "react-i18next";
import UserTable from "../../molecules/Tables/User";
import theme from "../../../theme";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TopSection = styled.div`
	display: flex;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleSection = styled.div`
	display: block;
	
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Title = styled.div`
`;

/**
 *
 * @type {StyledComponent<"h2", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleText = styled.h2`
	font-size: 29px;
	color: ${theme.darkBlue};
	
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescription = styled.div`
`;

/**
 *
 * @type {StyledComponent<"p", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TitleDescriptionText = styled.label`
	font-size: 15px;
	color: ${theme.lightBlue};
	@media ${device.mobileL} {
		font-size: 16px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonEpisode = styled.div`
	margin-left: auto;
	
	@media ${device.ipad} {
		margin-right: 15px;
	}
	
	@media ${device.mobileL} {
		margin-right: 15px;
		margin-top: 10px;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const TableSection = styled.div`
	margin-top: 20px;
	
	@media ${device.ipad} {
		margin-right: 15px;
	}
	
	@media ${device.mobileL} {
		margin-right: 15px;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const User = () => {

	const { t } = useTranslation();

	return (
		<>
			<Container>
				<TopSection>
					<TitleSection>
						<Title>
							<TitleText>Kullanıcılar</TitleText>
						</Title>
						<TitleDescription>
							<TitleDescriptionText>Bu sayfa üzerinden tüm kullanıcıları listeleyebilirsiniz.</TitleDescriptionText>
						</TitleDescription>
					</TitleSection>
					<ButtonEpisode>
						<Button
							type="primary"
							href={'/create/new/user'}
							block>
							+ Yeni Kullanıcı Ekle
						</Button>
					</ButtonEpisode>
				</TopSection>
				<TableSection>
					<UserTable/>
				</TableSection>
			</Container>
		</>
	);
};
export default User;
